import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import {
  HubspotPageType,
  HubspotSearchAndReplaceType,
  HubspotTransferType,
} from "./types";

export const getHubspotPagesSitePages = (
  queryParams?: QueryParamsType<HubspotPageType>
): APICall<Paginated<HubspotPageType[]>> =>
  request({
    url: `/hubspot-pages/site-pages${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const postTransferHubspotPagesSitePage = (
  data: HubspotTransferType
): APICall<Paginated<HubspotPageType[]>> =>
  request({
    data,
    url: `/hubspot-pages/site-pages/transfer`,
    method: "POST",
  });

export const postSearchAndReplaceHubspotPagesSitePage = (
  data: HubspotSearchAndReplaceType
): APICall<Paginated<HubspotPageType[]>> =>
  request({
    data,
    url: `/hubspot-pages/site-pages/search-and-replace`,
    method: "POST",
  });

export const getHubspotPagesLandingPages = (
  queryParams?: QueryParamsType<HubspotPageType>
): APICall<Paginated<HubspotPageType[]>> =>
  request({
    url: `/hubspot-pages/landing-pages${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const postTransferHubspotPagesLandingPage = (
  data: HubspotTransferType
): APICall<Paginated<HubspotPageType[]>> =>
  request({
    data,
    url: `/hubspot-pages/landing-pages/transfer`,
    method: "POST",
  });

export const postSearchAndReplaceHubspotPagesLandingPage = (
  data: HubspotSearchAndReplaceType
): APICall<Paginated<HubspotPageType[]>> =>
  request({
    data,
    url: `/hubspot-pages/landing-pages/search-and-replace`,
    method: "POST",
  });

export const getHubspotBlogs = (
  queryParams?: QueryParamsType<HubspotPageType>
): APICall<Paginated<HubspotPageType[]>> =>
  request({
    url: `/hubspot-pages/blogs${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });
