import { Grid, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { WithChildren } from "utils/types";

import LoginLanguageSwitcher from "./LoginLanguageSwitcher";

const LoginLayout: FC<WithChildren> = ({ children }) => {
  return (
    <Grid
      minHeight="100vh"
      container
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundImage: "url('/media/auth-bg.png')",
      }}
    >
      <Grid
        item
        lg={4}
        xs={12}
        order={{ lg: 1, xs: 2 }}
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundImage: "url('/media/login-info-bg.svg')",
        }}
      >
        <Stack
          direction="column"
          py={5}
          maxWidth="340px"
          height="100%"
          justifyContent="center"
          mx="auto"
        >
          <Typography variant="h3" fontWeight="bold" color="#fff" mb={4}>
            <FormattedMessage id="LOGIN.INFO.TITLE" />
          </Typography>
          <Typography color="#fff" mb={3}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus magna fringilla urna,
            porttitor rhoncus dolor purus non enim
          </Typography>
          <Typography color="#fff" mb={3}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus magna fringilla urna,
            porttitor rhoncus dolor purus non enim
          </Typography>
          <Typography color="#fff" mb={3}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus magna fringilla urna,
            porttitor rhoncus dolor purus non enim
          </Typography>

          {/* <Typography
            color="#fff"
            fontWeight="semibold"
            position="absolute"
            bottom={30}
            left={50}
          >
            <FormattedMessage id="LOGIN.INFO.APP" />
          </Typography> */}
          <LoginLanguageSwitcher />
        </Stack>
      </Grid>
      <Grid item lg={8} xs={12} order={{ lg: 2, xs: 1 }}>
        <Stack
          direction="column"
          py={5}
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          {children}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default LoginLayout;
