import { Business as BusinessIcon } from "@mui/icons-material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import TableHeader from "components/Table/components/TableHeader";

const CompaniesHeader: FC = () => {
  return (
    <>
      <TableHeader
        icon={BusinessIcon}
        label={<FormattedMessage id="COMPANIES.TABLE.TITLE" />}
      ></TableHeader>
    </>
  );
};

export default CompaniesHeader;
