import { Paper } from "@mui/material";
import React from "react";

import ApiKeyGenerator from "./ApiKeyGenerator";
import ApiKeyHeader from "./ApiKeyHeader/ApiKeyHeader";

const ApiKey = () => {
  return (
    <>
      <Paper>
        <ApiKeyHeader />
        <ApiKeyGenerator />
      </Paper>
    </>
  );
};

export default ApiKey;
