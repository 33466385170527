import { createBrowserHistory } from "history";
import React from "react";
import { Redirect, Switch } from "react-router-dom";

import AccessTokensPage from "containers/access-tokens";
import ApiKey from "containers/api-key";
import CompaniesPage from "containers/companies";
import CompaniesSchemasPage from "containers/companies/schemas";
import ContactsPage from "containers/contacts";
import ContactSchemasPage from "containers/contacts/schemas";
import DealsPage from "containers/deals";
import DealsSchemasPage from "containers/deals/schemas";
import DomainsPage from "containers/domains";
import FilesPage from "containers/files";
import ForgotPasswordPage from "containers/forgotPassword";
import HubdbsPage from "containers/hubdbs";
import LoginPage from "containers/login";
import MyOrganisationPage from "containers/my-organisation";
import OrganisationsPage from "containers/organisations";
import OrganisationDetails from "containers/organisations/details";
import BlogsPage from "containers/pages/blogs";
import LandingPagesPage from "containers/pages/landing-pages";
import SitePagesPage from "containers/pages/site-pages";
import RedirectsPage from "containers/redirects";
import RegisterPage from "containers/register";
import TemplatesPage from "containers/templates";
import UserNotifications from "containers/user/notifications";
import UserProfile from "containers/user/profile";
import UsersPage from "containers/users";
import WorkflowsPage from "containers/workflows";

import AuthRoute from "./components/routes/AuthRoute";
import PrivateRoute from "./components/routes/PrivateRoute";
import DashboardPage from "./containers/dashboard";
import Providers from "./Providers";

export const history = createBrowserHistory();

export default function App() {
  return (
    <Providers history={history}>
      <Switch>
        <AuthRoute exact component={LoginPage} path="/login" />
        <AuthRoute
          exact
          component={ForgotPasswordPage}
          path="/forgot-password"
        />
        <AuthRoute exact component={RegisterPage} path="/register/:id" />

        <PrivateRoute
          exact
          path="/dashboard"
          component={DashboardPage}
          breadcrumbs={[
            { translationId: "HEADER.DASHBOARD", pathname: "/dashboard" },
          ]}
          // requiredPermissions={[]}
        />

        {/* CMS */}
        <PrivateRoute
          exact
          path="/cms/domains"
          component={DomainsPage}
          breadcrumbs={[
            { translationId: "HEADER.DOMAINS", pathname: "/cms/domains" },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/cms/templates"
          component={TemplatesPage}
          breadcrumbs={[
            { translationId: "HEADER.TEMPLATES", pathname: "/cms/templates" },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/cms/files"
          component={FilesPage}
          breadcrumbs={[
            { translationId: "HEADER.FILES", pathname: "/cms/files" },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/cms/pages/site-pages"
          component={SitePagesPage}
          breadcrumbs={[
            {
              translationId: "HEADER.PAGES",
              pathname: "/cms/pages",
            },
            {
              translationId: "HEADER.PAGES.SITE_PAGES",
              pathname: "/cms/pages/site-pages",
            },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/cms/pages/landing-pages"
          component={LandingPagesPage}
          breadcrumbs={[
            {
              translationId: "HEADER.PAGES",
              pathname: "/cms/pages",
            },
            {
              translationId: "HEADER.PAGES.LANDING_PAGES",
              pathname: "/cms/pages/landing-pages",
            },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/cms/pages/blogs"
          component={BlogsPage}
          breadcrumbs={[
            {
              translationId: "HEADER.PAGES",
              pathname: "/cms/pages",
            },
            {
              translationId: "HEADER.PAGES.BLOGS",
              pathname: "/cms/pages/blogs",
            },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/cms/redirects"
          component={RedirectsPage}
          breadcrumbs={[
            { translationId: "HEADER.REDIRECTS", pathname: "/cms/redirects" },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/cms/hubdb"
          component={HubdbsPage}
          breadcrumbs={[
            { translationId: "HEADER.HUBDB", pathname: "/cms/hubdb" },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/cms/hubdb/:tableId"
          component={HubdbsPage}
          breadcrumbs={[
            { translationId: "HEADER.HUBDB", pathname: "/cms/hubdb" },
          ]}
          // requiredPermissions={[]}
        />

        {/* CRM */}
        <PrivateRoute
          exact
          path="/crm/contacts"
          component={ContactsPage}
          breadcrumbs={[
            { translationId: "HEADER.CONTACTS", pathname: "/crm/contacts" },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/crm/contact-schemas"
          component={ContactSchemasPage}
          breadcrumbs={[
            {
              translationId: "HEADER.CONTACTS.SCHEMAS",
              pathname: "/crm/contacts-schemas",
            },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/crm/companies"
          component={CompaniesPage}
          breadcrumbs={[
            { translationId: "HEADER.COMPANIES", pathname: "/crm/companies" },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/crm/company-schemas"
          component={CompaniesSchemasPage}
          breadcrumbs={[
            {
              translationId: "HEADER.COMPANIES.SCHEMAS",
              pathname: "/crm/company-schemas",
            },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/crm/deals"
          component={DealsPage}
          breadcrumbs={[
            { translationId: "HEADER.DEALS", pathname: "/crm/deals" },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/crm/deal-schemas"
          component={DealsSchemasPage}
          breadcrumbs={[
            {
              translationId: "HEADER.DEALS.SCHEMAS",
              pathname: "/crm/deal-schemas",
            },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/crm/workflows"
          component={WorkflowsPage}
          breadcrumbs={[
            { translationId: "HEADER.WORKFLOWS", pathname: "/cms/workflows" },
          ]}
          // requiredPermissions={[]}
        />

        {/* ADMINISTRATIVE */}
        <PrivateRoute
          exact
          path="/users"
          component={UsersPage}
          breadcrumbs={[{ translationId: "HEADER.USERS", pathname: "/users" }]}
          requiredRole={["portal-admin", "organisation-owner"]}
          requiredPermissions={["read:users"]}
        />
        <PrivateRoute
          exact
          path="/organisations"
          component={OrganisationsPage}
          breadcrumbs={[
            {
              translationId: "HEADER.ORGANISATIONS",
              pathname: "/organisations",
            },
          ]}
          requiredRole={["portal-admin"]}
          requiredPermissions={["read:organisations"]}
        />
        <PrivateRoute
          exact
          path="/organisations/:organisationId"
          component={OrganisationDetails}
          breadcrumbs={[
            {
              translationId: "HEADER.ORGANISATIONS",
              pathname: "/organisations",
            },
            {
              translationId: "GLOBAL.DETAILS",
              pathname: "#",
            },
          ]}
          requiredRole={["portal-admin"]}
          requiredPermissions={["read:organisations"]}
        />

        <PrivateRoute
          exact
          path="/my-organisation"
          component={MyOrganisationPage}
          breadcrumbs={[
            {
              translationId: "HEADER.MY_ORGANISATION",
              pathname: "/my-organisation",
            },
          ]}
          requiredRole={["organisation-owner", "organisation-employee"]}
          requiredPermissions={["read:organisations"]}
        />
        <PrivateRoute
          exact
          path="/access-tokens"
          component={AccessTokensPage}
          breadcrumbs={[
            {
              translationId: "HEADER.ACCESS_TOKENS",
              pathname: "/access-tokens",
            },
          ]}
          requiredRole={[
            "portal-admin",
            "organisation-owner",
            "organisation-employee",
          ]}
        />

        <PrivateRoute
          exact
          path="/user/profile"
          component={UserProfile}
          breadcrumbs={[
            {
              translationId: "HEADER.USER",
              pathname: "/user/profile",
            },
            {
              translationId: "HEADER.PROFILE",
              pathname: "/user/profile",
            },
          ]}
        />
        <PrivateRoute
          exact
          path="/api-key"
          component={ApiKey}
          breadcrumbs={[
            {
              translationId: "HEADER.API_KEY",
              pathname: "/api-key",
            },
          ]}
        />
        <PrivateRoute
          exact
          path="/user/notifications"
          component={UserNotifications}
          breadcrumbs={[
            {
              translationId: "HEADER.USER",
              pathname: "/user/profile",
            },
            {
              translationId: "HEADER.NOTIFICATIONS",
              pathname: "/user/notifications",
            },
          ]}
        />

        <Redirect to="/dashboard" />
      </Switch>
    </Providers>
  );
}
