import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import FilesHeader from "./FilesHeader/FilesHeader";
import FilesTable from "./FilesTable";

const FilesPage = () => {
  return (
    <>
      <AccessTokenPicker scope={["files"]} />

      <Paper>
        <FilesHeader />
        <FilesTable />
      </Paper>
    </>
  );
};

export default FilesPage;
