import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import BlogsHeader from "./BlogsHeader/BlogsHeader";
import BlogsTable from "./BlogsTable";

const BlogsPage = () => {
  return (
    <>
      <AccessTokenPicker scope={["content"]} />

      <Paper>
        <BlogsHeader />
        <BlogsTable />
      </Paper>
    </>
  );
};

export default BlogsPage;
