import { Box, Link, Stack } from "@mui/material";
import { CellProps } from "react-table";

import { HubspotFileType } from "api/hubspot-files/types";
import NoImagePNG from "assets/images/no-image-placeholder.png";

interface Props<T extends {}> extends CellProps<T> {}

const FilesNameFormatter = ({ row, value }: Props<HubspotFileType>) => {
  // const displayLink = Boolean(row.original.access === "PUBLIC_INDEXABLE");
  const displayLink = true;
  const displayImage = Boolean(
    row.original.type === "IMG" &&
      displayLink &&
      row.original.meta.allows_anonymous_access
  );

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box sx={{ border: "2px solid #Eee" }}>
        {displayImage ? (
          <img
            src={row.original.url}
            alt={row.original.title}
            width="80"
            height="53"
            loading="lazy"
            style={{ objectFit: "contain" }}
          />
        ) : (
          <img
            src={NoImagePNG}
            alt="Empty Asset"
            width="80"
            height="53"
            loading="lazy"
            style={{ objectFit: "cover" }}
          />
        )}
      </Box>

      {displayLink ? (
        <Link component="a" href={row.original.url} target="_blank">
          {`${row.original.title}.${row.original.extension}`}
        </Link>
      ) : (
        <Box> {`${row.original.title}.${row.original.extension}`}</Box>
      )}
    </Stack>
  );
};

export default FilesNameFormatter;
