import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Box, Grid, InputAdornment, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { getHubspotTemplates } from "api/hubspot-templates";
import { hubspotTemplatesKeys } from "api/hubspot-templates/queries";
// import { hasPermissions } from "components/stores/UserStore";
import { HubspotTemplateType } from "api/hubspot-templates/types";
import Table from "components/Table";
import {
  bytesColorFormatter,
  bytesFormatter,
} from "components/Table/formatters/bytesFormatter";
import { hubspotFormatter } from "components/Table/formatters/hubspotFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
// import { useModal } from "utils/hooks/useModal";

// import SitePagesActionsFormatter from "./formatters/SitePagesActionsFormatter";
import { useTemplatesFilter } from "./useTemplatesFilter";

const SitePagesTable: FC = () => {
  const intl = useIntl();
  const { filters, searchField } = useTemplatesFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    hubspotTemplatesKeys.list(),
    getHubspotTemplates,
    filters
  );

  const columns: Column<HubspotTemplateType>[] = [
    {
      accessor: "id",
      Header: intl.formatMessage({ id: "GLOBAL.ID" }),
      Cell: ({ value }: CellProps<HubspotTemplateType>) =>
        hubspotFormatter(value),
      Aggregated: ({ row }: CellProps<HubspotTemplateType>) => (
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          {...row.getToggleRowExpandedProps()}
        >
          <>
            {row.isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            {row.original.children?.length} Files
          </>
        </Stack>
      ),
    },
    {
      accessor: "path",
      Header: intl.formatMessage({ id: "TEMPLATES.TABLE.PATH" }),
    },
    {
      accessor: "size",
      Header: intl.formatMessage({ id: "TEMPLATES.TABLE.SIZE" }),
      Cell: ({ value }: CellProps<HubspotTemplateType>) => (
        <Box sx={{ color: bytesColorFormatter(value) }} fontWeight="bold">
          {bytesFormatter(value)}
        </Box>
      ),
    },
    // {
    //   Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
    //   Cell: (cell: CellProps<HubspotTemplateType>) => (
    //     <SitePagesActionsFormatter
    //       {...cell}
    //       // handleOpenDelete={handleOpenDelete}
    //     />
    //   ),
    //   align: "right",
    // },
  ];

  const getSubRows = useCallback(
    (templatesRow: any) => (templatesRow.children ? templatesRow.children : []),
    []
  );

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="TEMPLATES.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
        getSubRows={getSubRows}
      />
    </>
  );
};

export default SitePagesTable;
