import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import TemplatesHeader from "./TemplatesHeader/TemplatesHeader";
import TemplatesTable from "./TemplatesTable";

const TemplatesPage = () => {
  return (
    <>
      <AccessTokenPicker scope={["content"]} />

      <Paper>
        <TemplatesHeader />
        <TemplatesTable />
      </Paper>
    </>
  );
};

export default TemplatesPage;
