import { QueryKey } from "react-query";

const organisationsKeys = {
  all: ["organisations"] as const,
  list: () => [...organisationsKeys.all, "list"],
  listFiltered: (filters: QueryKey) => [...organisationsKeys.list(), filters],
  details: (id: QueryKey) => [...organisationsKeys.all, "detail", id] as const,
  mine: () => [...organisationsKeys.all, "mine"],
};

export { organisationsKeys };
