import {
  DeleteOutlined as DeleteIcon,
  MoveDown as MoveDownIcon,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { CellProps } from "react-table";

import { HubspotPageType } from "api/hubspot-pages/types";
import IconButtonWithTooltip from "components/Icons/IconButtonWithTooltip";

interface Props<T extends {}> extends CellProps<T> {
  handleOpenTransfer: (ctx?: T) => void;
}

const ActionsFormatter = ({
  row,
  handleOpenTransfer,
}: // handleOpenDelete,
Props<HubspotPageType>) => {
  return (
    <Box>
      <IconButtonWithTooltip
        tooltipText={<FormattedMessage id="GLOBAL.TRANSFER_TO_PORTAL" />}
        // data-testid="delete-port-button"
        color="primary"
        onClick={() => handleOpenTransfer(row.original)}
      >
        <MoveDownIcon />
      </IconButtonWithTooltip>

      <IconButtonWithTooltip
        tooltipText={<FormattedMessage id="GLOBAL.DELETE" />}
        // data-testid="delete-port-button"
        color="primary"
        // onClick={() => handleOpenDelete(row.original)}
      >
        <DeleteIcon />
      </IconButtonWithTooltip>
      {/* )} */}
    </Box>
  );
};

export default ActionsFormatter;
