import { Box, Stack } from "@mui/material";

import { ReactComponent as HubspotSVG } from "assets/icons/hubspot.svg";

export const hubspotFormatter = (id: string | null | undefined) => (
  <Stack direction="row" alignItems="center">
    <HubspotSVG style={{ height: "15px", width: "15px", marginRight: "5px" }} />
    <Box>{id}</Box>
  </Stack>
);
