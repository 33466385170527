import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  FiberManualRecord as FiberManualRecordIcon,
  KeyOff,
} from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Collapse } from "@mui/material";
import { List } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { UserOrgTypeRoleType } from "api/user/types";
import { hasScopes } from "components/stores/UserStore";

import SidebarMenuItem, { SidebarMenuItemsProps } from "./SidebarMenuItem";

export interface SidebarMenuDropdownProps {
  icon: JSX.Element;
  text: string | JSX.Element;
  link: string;
  list: Array<
    Omit<
      SidebarMenuItemsProps & {
        requiredRole?: UserOrgTypeRoleType[];
        scopes?: string[];
      },
      "icon"
    >
  >;
  onClick?: () => void;
  missingScopes?: string[];
}

const SidebarMenuDropdown = ({
  icon,
  text,
  link,
  list,
  onClick,
  missingScopes,
}: SidebarMenuDropdownProps) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((p) => !p);
  };

  const isDisabled = Boolean(missingScopes?.length);
  const isSelected = pathname.includes(link);

  const tooltipTitle = missingScopes?.length
    ? `Missing scopes: ${missingScopes.join(", ")}`
    : "";

  return (
    <>
      <Tooltip title={tooltipTitle} placement="right">
        <MenuItem
          onClick={handleClick}
          sx={{
            py: 1.3,
            pl: 0,
            mb: 0.5,
            mx: 1,
            borderRadius: 2,
            overflow: "hidden",
            backgroundColor: isDisabled
              ? theme.palette.grey[200]
              : "transparent",
          }}
          selected={isSelected}
        >
          <ListItemIcon
            sx={{
              width: 48,
              justifyContent: "center",
              color: isSelected
                ? theme.palette.primary.dark
                : theme.palette.grey[500],
              "& svg g [fill]": {
                fill: isSelected
                  ? theme.palette.primary.dark
                  : theme.palette.grey[500],
              },
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            sx={{
              color: isSelected
                ? theme.palette.primary.dark
                : theme.palette.grey[800],
            }}
          >
            {text}
          </ListItemText>

          {isDisabled ? (
            <KeyOff sx={{ fontSize: "16px", color: theme.palette.grey[400] }} />
          ) : open ? (
            <ExpandLessIcon
              sx={{
                color: isSelected
                  ? theme.palette.primary.dark
                  : theme.palette.grey[500],
              }}
            />
          ) : (
            <ExpandMoreIcon
              sx={{
                color: isSelected
                  ? theme.palette.primary.dark
                  : theme.palette.grey[500],
              }}
            />
          )}
        </MenuItem>
      </Tooltip>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {list.map(({ text, link, scopes }) => {
            const missingScope = !hasScopes(scopes);

            return (
              <SidebarMenuItem
                key={link}
                icon={
                  <FiberManualRecordIcon sx={{ transform: "scale(0.5)" }} />
                }
                text={text}
                link={link}
                onClick={onClick}
                missingScopes={missingScope ? scopes : []}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export default SidebarMenuDropdown;
