import { Visibility as VisibilityIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { CellProps } from "react-table";

import { HubspotHubdbType } from "api/hubspot-hubdbs/types";
import IconButtonWithTooltip from "components/Icons/IconButtonWithTooltip";

interface Props<T extends {}> extends CellProps<T> {}

const ActionsFormatter = ({ row }: Props<HubspotHubdbType>) => {
  return (
    <Box>
      <IconButtonWithTooltip
        tooltipText={<FormattedMessage id="GLOBAL.VIEW" />}
        // data-testid="delete-port-button"
        color="primary"
        // onClick={() => handleOpenDelete(row.original)}
      >
        <VisibilityIcon />
      </IconButtonWithTooltip>
    </Box>
  );
};

export default ActionsFormatter;
