import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Chip, Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  CellProps,
  //  CellProps,
  Column,
} from "react-table";

import { getHubspotHubdbs } from "api/hubspot-hubdbs";
import { hubspotHubdbsKeys } from "api/hubspot-hubdbs/queries";
import { HubspotHubdbType } from "api/hubspot-hubdbs/types";
// import { hasPermissions } from "components/stores/UserStore";
import Table from "components/Table";
import { hubspotFormatter } from "components/Table/formatters/hubspotFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
// import { useModal } from "utils/hooks/useModal";

// import DeleteHubdbModal from "./components/DeleteHubdbModal";
import HubdbsActionsFormatter from "./formatters/HubdbsActionsFormatter";
import { useHubdbsFilter } from "./useHubdbsFilter";

const HubdbsTable: FC = () => {
  const intl = useIntl();
  const { filters, searchField } = useHubdbsFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    hubspotHubdbsKeys.list(),
    getHubspotHubdbs,
    filters
  );

  const columns: Column<HubspotHubdbType>[] = [
    {
      accessor: "id",
      Header: intl.formatMessage({ id: "GLOBAL.ID" }),
      Cell: ({ value }: CellProps<HubspotHubdbType>) => hubspotFormatter(value),
    },
    {
      accessor: "label",
      Header: intl.formatMessage({ id: "GLOBAL.LABEL" }),
    },
    {
      accessor: "name",
      Header: intl.formatMessage({ id: "GLOBAL.NAME" }),
    },
    {
      accessor: "columnCount",
      Header: intl.formatMessage({ id: "HUBDBS.TABLE.COLUMN_COUNT" }),
    },
    {
      accessor: "rowCount",
      Header: intl.formatMessage({ id: "HUBDBS.TABLE.ROW_COUNT" }),
    },
    {
      accessor: "published",
      Header: intl.formatMessage({ id: "GLOBAL.PUBLISHED" }),
      Cell: ({ value }: CellProps<HubspotHubdbType>) => (
        <>
          {value ? (
            <Chip
              icon={<CheckCircleIcon />}
              label={intl.formatMessage({ id: "GLOBAL.PUBLISHED" })}
              size="small"
              color="success"
              sx={{ color: "#fff" }}
            />
          ) : (
            <Chip
              icon={<CancelIcon />}
              label={intl.formatMessage({ id: "GLOBAL.DRAFT" })}
              size="small"
              color="error"
            />
          )}
        </>
      ),
    },
    {
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: CellProps<HubspotHubdbType>) => (
        <HubdbsActionsFormatter {...cell} />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="HUBDBS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />
    </>
  );
};

export default HubdbsTable;
