import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import CompaniesSchemasHeader from "./CompaniesSchemasHeader";
import CompaniesSchemasTable from "./CompaniesSchemasTable";

const CompaniesSchemasPage = () => {
  return (
    <>
      <AccessTokenPicker scope={["crm.schemas.custom.read"]} />

      <Paper>
        <CompaniesSchemasHeader />
        <CompaniesSchemasTable />
      </Paper>
    </>
  );
};

export default CompaniesSchemasPage;
