import { Compress as CompressIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { queryCache, useMutation } from "react-query";

import { postHubspotCompressFile as postHubspotCompressFileAPI } from "api/hubspot-files";
import { hubspotFilesKeys } from "api/hubspot-files/queries";
import {
  HubspotCompressFileType,
  HubspotFileType,
} from "api/hubspot-files/types";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
import FormikRangeField from "components/forms/FormikRangeField";
import FormikTextField from "components/forms/FormikTextField";
import { bytesFormatter } from "components/Table/formatters/bytesFormatter";

interface CompressFileModalProps {
  isOpen: boolean;
  handleClose: () => void;
  file: HubspotFileType | undefined;
}

type HubspotFormCompressFileType = {
  fileId: string | undefined;
  name: string | undefined;
  mimetype: "jpeg";
  quality: number;
};

const CompressFileModal = ({
  isOpen,
  handleClose,
  file,
}: CompressFileModalProps) => {
  const [responseData, setResponseData] = useState<any>({});
  const [step, setStep] = useState<0 | 1>(0);

  useEffect(() => {
    setStep(0);
  }, [file]);

  const [compressFile, { status }] = useMutation(
    async (data: HubspotCompressFileType) => {
      const res = await postHubspotCompressFileAPI(data);
      await queryCache.invalidateQueries(hubspotFilesKeys.list());
      return res.data;
    }
  );

  const handleSubmit = async (values: HubspotFormCompressFileType) => {
    const mappedValues: HubspotCompressFileType = {
      ...values,
      fileId: values?.fileId ?? "",
    };

    await compressFile(mappedValues, {
      onSuccess: (data: any) => {
        setStep(1);
        setResponseData(data);
        queryCache.invalidateQueries(hubspotFilesKeys.list());
      },
    });
  };

  const isCompressed = useMemo(() => {
    if (responseData.compressedBy > 0) {
      return true;
    }
    return false;
  }, [responseData]);

  const marks = [
    {
      value: 10,
      label: "10%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fileId: file?.id,
      name: file?.title,
      mimetype: "jpeg",
      quality: 90,
    },
    // validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { values } = formik;

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <CompressIcon sx={{ mr: 1 }} />
          <FormattedMessage id="FILES.COMPRESS.TITLE" />
        </Stack>
      </DialogTitle>

      <Divider />

      <Stack direction="row" justifyContent="center">
        <Stepper
          sx={{ maxWidth: 450, my: 2, alignSelf: "center", width: "100%" }}
          nonLinear
          activeStep={step}
        >
          <Step completed={step > 0}>
            <StepLabel>Optimize</StepLabel>
          </Step>
          <Step>
            <StepLabel>Results</StepLabel>
          </Step>
        </Stepper>
      </Stack>

      <img
        style={{
          objectFit: "contain",
          display: "block",
          margin: "0 auto",
          border: "2px solid #eee",
        }}
        width="450"
        height="298"
        src={file?.url}
        alt={file?.title}
      />

      {step === 0 ? (
        <FormikProvider value={formik}>
          <FormikForm>
            <DialogContent>
              <FormikTextField
                name="name"
                sx={{ mb: 1 }}
                label={<FormattedMessage id="GLOBAL.NAME" />}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography sx={{ fontSize: "14px" }}>
                        .{values.mimetype}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
              <FormikAutocomplete
                name="mimetype"
                sx={{ mb: 1 }}
                options={[{ label: "JPEG", value: "jpeg" }]}
                label={<FormattedMessage id="FILES.COMPRESS.FORM.MIMETYPE" />}
              />
              {/* <FormikNumberField
                name="quality"
                sx={{ mb: 1 }}
                suffix="%"
                label={<FormattedMessage id="FILES.COMPRESS.FORM.QUALITY" />}
              /> */}
              <FormikRangeField
                name="quality"
                min={10}
                max={100}
                // defaultValue={90}
                label={<FormattedMessage id="FILES.COMPRESS.FORM.QUALITY" />}
                marks={marks}
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose}>
                <FormattedMessage
                  // data-testid="delete-port-close-button"
                  id="GLOBAL.CANCEL"
                />
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={status === "loading"}
              >
                <FormattedMessage id="GLOBAL.COMPRESS" />
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        </FormikProvider>
      ) : (
        <>
          <DialogContent>
            {isCompressed ? (
              <Stack direction="column" divider={<Divider />} spacing={1}>
                <Stack direction="row" justifyContent={"space-between"}>
                  <Typography>Reduced size by</Typography>
                  <Typography
                    fontWeight="bold"
                    sx={{
                      color: "#008000",
                    }}
                  >
                    -{bytesFormatter(responseData?.compressedBy ?? 0)}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent={"space-between"}>
                  <Typography>Reduced size in percentage</Typography>
                  <Typography fontWeight="bold" sx={{ color: "#008000" }}>
                    -
                    {responseData?.compressedByPrecentage
                      ? responseData?.compressedByPrecentage.toFixed(1)
                      : 0}
                    %
                  </Typography>
                </Stack>
              </Stack>
            ) : (
              <Stack direction="column">
                <Alert severity="error" sx={{ mt: 1 }}>
                  Image size was not reduced please select different parameters
                  and try again!
                </Alert>
              </Stack>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>
              <FormattedMessage
                // data-testid="delete-port-close-button"
                id="GLOBAL.CLOSE"
              />
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CompressFileModal;
