import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Chip, Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { getHubspotWorkflows } from "api/hubspot-workflows";
import { hubspotWorkflowsKeys } from "api/hubspot-workflows/queries";
// import { hasPermissions } from "components/stores/UserStore";
import { HubspotWorkflowType } from "api/hubspot-workflows/types";
import Table from "components/Table";
import { hubspotFormatter } from "components/Table/formatters/hubspotFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { useModal } from "utils/hooks/useModal";

import DeleteWorkflowModal from "./components/DeleteWorkflowModal";
import TransferWorkflowModal from "./components/TransferWorkflowModal";
import WorkflowsActionsFormatter from "./formatters/WorkflowsActionsFormatter";
import { useWorkflowsFilter } from "./useWorkflowsFilter";

const WorkflowsTable: FC = () => {
  const intl = useIntl();
  const { filters, searchField } = useWorkflowsFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    hubspotWorkflowsKeys.list(),
    getHubspotWorkflows,
    filters
  );

  const {
    isOpen: isOpenDelete,
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    context: deleteContext,
  } = useModal<HubspotWorkflowType>();

  const {
    isOpen: isOpenTransfer,
    handleClose: handleCloseTransfer,
    handleOpen: handleOpenTransfer,
    context: transferContext,
  } = useModal<HubspotWorkflowType>();

  const columns: Column<HubspotWorkflowType>[] = [
    {
      accessor: "id",
      Header: intl.formatMessage({ id: "GLOBAL.ID" }),
      Cell: ({ value }: CellProps<HubspotWorkflowType>) =>
        hubspotFormatter(value),
    },
    {
      accessor: "name",
      Header: intl.formatMessage({ id: "WORKFLOWS.TABLE.NAME" }),
    },
    {
      id: "active",
      Header: intl.formatMessage({ id: "WORKFLOWS.TABLE.STATUS" }),
      Cell: ({ row }: CellProps<HubspotWorkflowType>) => (
        <>
          {row.original.enabled ? (
            <Chip
              icon={<CheckCircleIcon />}
              label={intl.formatMessage({ id: "GLOBAL.ACTIVE" })}
              size="small"
              color="success"
              sx={{ color: "#fff" }}
            />
          ) : (
            <Chip
              icon={<CancelIcon />}
              label={intl.formatMessage({ id: "GLOBAL.INACTIVE" })}
              size="small"
              color="error"
            />
          )}
        </>
      ),
    },
    {
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: CellProps<HubspotWorkflowType>) => (
        <WorkflowsActionsFormatter
          {...cell}
          handleOpenDelete={handleOpenDelete}
          handleOpenTransfer={handleOpenTransfer}
        />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="WORKFLOWS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />

      <DeleteWorkflowModal
        isOpen={isOpenDelete}
        handleClose={handleCloseDelete}
        workflow={deleteContext}
      />

      <TransferWorkflowModal
        isOpen={isOpenTransfer}
        handleClose={handleCloseTransfer}
        context={transferContext}
      />
    </>
  );
};

export default WorkflowsTable;
