import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  CellProps,
  //  CellProps,
  Column,
} from "react-table";

import { getHubspotContacts } from "api/hubspot-contacts";
import { hubspotContactsKeys } from "api/hubspot-contacts/queries";
import { HubspotContactType } from "api/hubspot-contacts/types";
// import { hasPermissions } from "components/stores/UserStore";
import Table from "components/Table";
import { hubspotFormatter } from "components/Table/formatters/hubspotFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { useModal } from "utils/hooks/useModal";

import DeleteHubspotContactModal from "./components/DeleteContactModal";
import TransferContactModal from "./components/TransferContactModal";
import ContactsActionsFormatter from "./formatters/ContactsActionsFormatter";
import { useContactsFilter } from "./useContactsFilter";

const ContactsTable: FC = () => {
  const intl = useIntl();
  const { filters, searchField } = useContactsFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    hubspotContactsKeys.list(),
    getHubspotContacts,
    filters
  );

  const {
    isOpen: isOpenTransfer,
    handleClose: handleCloseTransfer,
    handleOpen: handleOpenTransfer,
    context: transferContext,
  } = useModal<HubspotContactType>();

  const {
    isOpen: isOpenDelete,
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    context: deleteContext,
  } = useModal<HubspotContactType>();

  const columns: Column<HubspotContactType>[] = [
    {
      accessor: "id",
      Header: intl.formatMessage({ id: "GLOBAL.ID" }),
      Cell: ({ value }: CellProps<HubspotContactType>) =>
        hubspotFormatter(value),
    },
    {
      id: "email",
      Header: intl.formatMessage({ id: "CONTACTS.TABLE.EMAIL" }),
      Cell: ({ row }: CellProps<HubspotContactType>) => (
        <>{row.original.properties.email}</>
      ),
    },
    {
      id: "name",
      Header: intl.formatMessage({ id: "GLOBAL.NAME" }),
      Cell: ({ row }: CellProps<HubspotContactType>) => (
        <>
          {row.original.properties.firstname} {row.original.properties.lastname}
        </>
      ),
    },
    {
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: CellProps<HubspotContactType>) => (
        <ContactsActionsFormatter
          {...cell}
          handleOpenDelete={handleOpenDelete}
          handleOpenTransfer={handleOpenTransfer}
        />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="CONTACTS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />

      <TransferContactModal
        isOpen={isOpenTransfer}
        handleClose={handleCloseTransfer}
        context={transferContext}
      />

      <DeleteHubspotContactModal
        isOpen={isOpenDelete}
        handleClose={handleCloseDelete}
        contact={deleteContext}
      />
    </>
  );
};

export default ContactsTable;
