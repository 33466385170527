import { Search as SearchIcon } from "@mui/icons-material";
import { Box, Grid, InputAdornment, Stack, TextField } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  CellProps,
  //  CellProps,
  Column,
} from "react-table";

import { getAccessTokens } from "api/hubspot-accessTokens";
import { hubspotAccessTokensKeys } from "api/hubspot-accessTokens/queries";
import { AccessTokenType } from "api/hubspot-accessTokens/types";
// import { hasPermissions } from "components/stores/UserStore";
import { useUserStore } from "components/stores/UserStore";
import Table from "components/Table";
import { hubspotFormatter } from "components/Table/formatters/hubspotFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { useModal } from "utils/hooks/useModal";

import DeleteAccessTokenModal from "./components/DeleteAccessTokenModal";
import AccessTokensActionsFormatter from "./formatters/AccessTokensActionsFormatter";
import SelectFormatter from "./formatters/SelectFormatter";
import { useAccessTokensFilter } from "./useAccessTokensFilter";

interface AccessTokensTableProps {
  handleOpenEdit: (ctx?: AccessTokenType | undefined) => void;
}

const AccessTokensTable: FC<AccessTokensTableProps> = ({ handleOpenEdit }) => {
  const intl = useIntl();
  const { filters, searchField } = useAccessTokensFilter();

  const [user] = useUserStore((s) => [s.user]);

  const {
    isOpen: isOpenDelete,
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    context: deleteContext,
  } = useModal<AccessTokenType>();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    hubspotAccessTokensKeys.list(),
    getAccessTokens,
    filters
  );

  const columns: Column<AccessTokenType>[] = [
    {
      accessor: "portalId",
      Header: intl.formatMessage({ id: "ACCESS_TOKENS.TABLE.PORTAL_ID" }),
      Cell: ({ value }: CellProps<AccessTokenType>) => hubspotFormatter(value),
    },
    {
      accessor: "name",
      Header: intl.formatMessage({ id: "GLOBAL.NAME" }),
    },
    {
      accessor: "scopes",
      Header: intl.formatMessage({ id: "ACCESS_TOKENS.TABLE.SCOPES" }),
      Cell: ({ value }: CellProps<AccessTokenType>) => (
        <Stack direction="row" flexWrap="wrap" gap={0.5}>
          {value.map((scope: string, index: number) => (
            <Box
              key={`scope-${index}`}
              sx={{
                display: "inline-block",
                px: 1,
                py: 0.5,
                borderRadius: 1,

                backgroundColor: (t) => t.palette.grey[200],
              }}
            >
              {scope}
            </Box>
          ))}
        </Stack>
      ),
    },
    {
      id: "default",
      Header: intl.formatMessage({ id: "ACCESS_TOKENS.TABLE.SELECT" }),
      Cell: (cell: CellProps<AccessTokenType>) => (
        <SelectFormatter
          {...cell}
          selectedAccessToken={user?.selectedAccessToken?._id ?? ""}
        />
      ),
      align: "center",
    },
    {
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: CellProps<AccessTokenType>) => (
        <AccessTokensActionsFormatter
          {...cell}
          handleOpenEdit={handleOpenEdit}
          handleOpenDelete={handleOpenDelete}
        />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="ACCESS_TOKENS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />

      <DeleteAccessTokenModal
        isOpen={isOpenDelete}
        handleClose={handleCloseDelete}
        accessToken={deleteContext}
      />
    </>
  );
};

export default AccessTokensTable;
