import { BusinessCenter as BusinessCenterIcon } from "@mui/icons-material";
import { ImageOutlined as ImageIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import { Formik, Form as FormikForm } from "formik";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import TableHeader from "components/Table/components/TableHeader";

import OrganisationForm from "./OrganisationForm/OrganisationForm";
import { useMyOrganisation } from "./useMyOrganisation";

const MyOrganisationPage = () => {
  const { organisation, initialValues, handleSubmit, isSubmitting } =
    useMyOrganisation();

  const schema = yup.object({
    name: yup.string().nullable().required(),
    email: yup.string().required().email(),
    city: yup.string().nullable().required(),
    address: yup.string().nullable().required(),
    postcode: yup.string().nullable().required(),
  });

  return (
    <>
      <Paper>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          <FormikForm>
            <TableHeader
              icon={BusinessCenterIcon}
              label={
                <FormattedMessage
                  id="ORGANISATIONS.DETAILS.HEADER"
                  values={{ organisationName: organisation?.name }}
                />
              }
            ></TableHeader>

            <Typography variant="h5" sx={{ mx: 2, mt: 2 }}>
              <FormattedMessage id="MY_ORGANISATION.FORM.GENERAL_INFORMATION" />
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ px: 2, pt: 1, pb: 3 }}
            >
              <Box sx={{ minWidth: 300, mr: 3 }}>
                <Paper
                  sx={{
                    height: 250,
                    borderWidth: "3px",
                    borderStyle: "dashed",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ImageIcon sx={{ fontSize: 100, color: "#EEE" }} />
                </Paper>
              </Box>
              <Box sx={{ width: "100%" }}>
                <OrganisationForm />
              </Box>
            </Stack>
            <Divider />
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              sx={{ p: 2 }}
            >
              <LoadingButton
                size="medium"
                variant="contained"
                loading={isSubmitting}
                type="submit"
              >
                <FormattedMessage id="GLOBAL.SAVE" />
              </LoadingButton>
            </Stack>
          </FormikForm>
        </Formik>
      </Paper>
    </>
  );
};

export default MyOrganisationPage;
