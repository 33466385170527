import { FileCopy as FileCopyIcon } from "@mui/icons-material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import TableHeader from "components/Table/components/TableHeader";

const FilesHeader: FC = () => {
  return (
    <>
      <TableHeader
        icon={FileCopyIcon}
        label={<FormattedMessage id="FILES.TABLE.TITLE" />}
      ></TableHeader>
    </>
  );
};

export default FilesHeader;
