import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { queryCache, useMutation } from "react-query";

import { deleteAccessToken as deleteAccessTokenAPI } from "api/hubspot-accessTokens";
import { hubspotAccessTokensKeys } from "api/hubspot-accessTokens/queries";
import { AccessTokenType } from "api/hubspot-accessTokens/types";

interface DeleteAccessTokenModalProps {
  isOpen: boolean;
  handleClose: () => void;
  accessToken: AccessTokenType | undefined;
}

const DeleteAccessTokenModal = ({
  isOpen,
  handleClose,
  accessToken,
}: DeleteAccessTokenModalProps) => {
  const [deleteAccessToken, { status }] = useMutation(
    async (accessTokenId: string) => {
      const res = await deleteAccessTokenAPI(accessTokenId);
      await queryCache.invalidateQueries(hubspotAccessTokensKeys.list());
      return res.data;
    }
  );

  const handleDelete = async () => {
    await deleteAccessToken(accessToken!._id, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <DeleteIcon sx={{ mr: 1 }} />
          <FormattedMessage id="ACCESS_TOKEN.DELETE.TITLE" />
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <FormattedMessage
          id="ACCESS_TOKEN.DELETE.WARNING"
          values={{ tokenName: <b>{accessToken?.name}</b> }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage
            // data-testid="delete-port-close-button"
            id="GLOBAL.CANCEL"
          />
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleDelete}
          loading={status === "loading"}
        >
          <FormattedMessage id="GLOBAL.DELETE" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAccessTokenModal;
