import { QueryKey } from "react-query";

const hubspotAccessTokensKeys = {
  all: ["access-tokens"] as const,
  list: () => [...hubspotAccessTokensKeys.all, "list"],
  listFiltered: (filters: QueryKey) => [
    ...hubspotAccessTokensKeys.list(),
    filters,
  ],
  url: () => [...hubspotAccessTokensKeys.all, "url"],
  details: (id: QueryKey) =>
    [...hubspotAccessTokensKeys.all, "details", id] as const,
};

export { hubspotAccessTokensKeys };
