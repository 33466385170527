import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { QueryStatus } from "react-query";
import * as yup from "yup";

import FormikTextField from "components/forms/FormikTextField";

export type AccessTokenFormValues = {
  name: string | null;
};

interface IAccessTokenForm {
  handleSubmit: (values: AccessTokenFormValues) => void;
  handleClose: () => void;
  initialValues?: AccessTokenFormValues;
  submitStatus?: QueryStatus;
  edit?: boolean;
}

const AccessTokenForm: FC<IAccessTokenForm> = ({
  handleSubmit,
  handleClose,
  initialValues = {
    name: null,
  },
  submitStatus,
  edit,
}) => {
  const schema = yup.object({
    name: yup.string().nullable().required(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <DialogContent>
          <FormikTextField
            name="name"
            label={<FormattedMessage id="GLOBAL.NAME" />}
          />
        </DialogContent>

        <DialogActions>
          <Button
            // data-testid="new-edit-port-close-button"
            onClick={handleClose}
          >
            <FormattedMessage id="GLOBAL.CLOSE" />
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            <FormattedMessage id="GLOBAL.SAVE" />
          </LoadingButton>
        </DialogActions>
      </FormikForm>
    </FormikProvider>
  );
};

export default AccessTokenForm;
