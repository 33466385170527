import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { HubspotCompressFileType, HubspotFileType } from "./types";

export const getHubspotFiles = (
  queryParams?: QueryParamsType<HubspotFileType>
): APICall<Paginated<HubspotFileType[]>> =>
  request({
    url: `/hubspot-files${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const postHubspotCompressFile = (
  data: HubspotCompressFileType
): APICall<null> =>
  request({
    data,
    url: `/hubspot-files/compress`,
    method: "POST",
  });
