import { Search as SearchIcon } from "@mui/icons-material";
import { Box, Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { getHubspotFiles } from "api/hubspot-files";
import { hubspotFilesKeys } from "api/hubspot-files/queries";
import { HubspotFileType } from "api/hubspot-files/types";
import Table from "components/Table";
import {
  bytesColorFormatter,
  bytesFormatter,
} from "components/Table/formatters/bytesFormatter";
import { hubspotFormatter } from "components/Table/formatters/hubspotFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { useModal } from "utils/hooks/useModal";

import CompressFileModal from "./components/CompressFileModal";
import FilesActionsFormatter from "./formatters/FilesActionsFormatter";
import FilesNameFormatter from "./formatters/FilesNameFormatter";
import { useFilesFilter } from "./useFilesFilter";

const FilesTable: FC = () => {
  const intl = useIntl();
  const { filters, searchField } = useFilesFilter();

  const {
    isOpen: isOpenCompression,
    handleClose: handleCloseCompression,
    handleOpen: handleOpenCompression,
    context: compressionContext,
  } = useModal<HubspotFileType>();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    hubspotFilesKeys.list(),
    getHubspotFiles,
    filters
  );

  const columns: Column<HubspotFileType>[] = [
    {
      accessor: "id",
      Header: intl.formatMessage({ id: "GLOBAL.ID" }),
      Cell: ({ value }: CellProps<HubspotFileType>) => hubspotFormatter(value),
    },
    {
      accessor: "title",
      Header: intl.formatMessage({ id: "GLOBAL.NAME" }),
      Cell: (cell: CellProps<HubspotFileType>) => (
        <FilesNameFormatter {...cell} />
      ),
    },
    {
      accessor: "size",
      Header: intl.formatMessage({ id: "FILES.TABLE.SIZE" }),
      Cell: ({ value }: CellProps<HubspotFileType>) => (
        <Box sx={{ color: bytesColorFormatter(value) }} fontWeight="bold">
          {bytesFormatter(value)}
        </Box>
      ),
    },
    {
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: CellProps<HubspotFileType>) => (
        <FilesActionsFormatter
          {...cell}
          handleOpenCompression={handleOpenCompression}
          // handleOpenDelete={handleOpenDelete}
        />
      ),
      align: "right",
    },
  ];
  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="FILES.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />

      <CompressFileModal
        isOpen={isOpenCompression}
        handleClose={handleCloseCompression}
        file={compressionContext}
      />
    </>
  );
};

export default FilesTable;
