import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import DomainsHeader from "./DomainsHeader/DomainsHeader";
import DomainsTable from "./DomainsTable";

const DomainsPage = () => {
  return (
    <>
      <AccessTokenPicker scope={["content"]} />

      <Paper>
        <DomainsHeader />
        <DomainsTable />
      </Paper>
    </>
  );
};

export default DomainsPage;
