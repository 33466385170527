import { LocalOffer as LocalOfferIcon } from "@mui/icons-material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import TableHeader from "components/Table/components/TableHeader";

const DealsHeader: FC = () => {
  return (
    <>
      <TableHeader
        icon={LocalOfferIcon}
        label={<FormattedMessage id="DEALS.TABLE.TITLE" />}
      ></TableHeader>
    </>
  );
};

export default DealsHeader;
