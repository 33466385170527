import { MoveDown as MoveDownIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import * as yup from "yup";

import { getAccessTokens } from "api/hubspot-accessTokens";
import { hubspotAccessTokensKeys } from "api/hubspot-accessTokens/queries";
import { AccessTokenType } from "api/hubspot-accessTokens/types";
import { QueryParamsType } from "api/utils";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
import { AutocompleteOptiontType } from "components/forms/FormikSelect";
import { useUserStore } from "components/stores/UserStore";

export type TransferBlogFormValues = {
  accessToken: string | null;
  page: string;
};

interface TransferBlogModalProps {
  isOpen: boolean;
  handleClose: () => void;
  page?: string;
}

const TransferBlogModal = ({
  isOpen,
  handleClose,
  page,
}: TransferBlogModalProps) => {
  const [user] = useUserStore((s) => [s.user]);

  const schema = yup.object({
    accessToken: yup.string().nullable().required(),
    page: yup.string().nullable().required(),
  });

  const initialValues = {
    accessToken: null,
    page,
  };

  const handleSubmit = () => {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const accessTokensFilter: QueryParamsType<AccessTokenType> = {
    filter: [
      {
        field: "_id",
        operator: "ne",
        value: user?.selectedAccessToken?._id || null,
      },
    ],
    limit: 1000,
  };
  const { data: accessTokens, isFetching: areTokensFetching } = useQuery(
    hubspotAccessTokensKeys.listFiltered(accessTokensFilter),
    async () => {
      const { data: res } = await getAccessTokens(accessTokensFilter);
      return res.data;
    }
  );

  const accessTokensOptions = useMemo<AutocompleteOptiontType[]>(() => {
    if (accessTokens) {
      return accessTokens?.map((token) => ({
        label: `${token.name}`,
        value: token._id,
      }));
    }
    return [];
  }, [accessTokens]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <MoveDownIcon sx={{ mr: 1 }} />
          <FormattedMessage id="PAGES.BLOGS.TABLE.TRANSFER" />
        </Stack>
      </DialogTitle>
      <Divider />

      <DialogContent>
        <FormikProvider value={formik}>
          <FormikForm>
            <FormikAutocomplete
              name="portalId"
              label={
                <FormattedMessage id="PAGES.BLOG.TABLE.TRANSFER.CHOOSE_TOKEN" />
              }
              options={accessTokensOptions}
              loading={areTokensFetching}
            />
          </FormikForm>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Button
          // data-testid="new-edit-port-close-button"
          onClick={handleClose}
        >
          <FormattedMessage id="GLOBAL.CLOSE" />
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          // loading={submitStatus === "loading"}
        >
          <FormattedMessage id="GLOBAL.TRANSFER" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TransferBlogModal;
