import { Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  CellProps,
  //  CellProps,
  Column,
} from "react-table";

import { getHubspotContactsSchemas } from "api/hubspot-contacts";
import { hubspotContactsKeys } from "api/hubspot-contacts/queries";
// import { hasPermissions } from "components/stores/UserStore";
import { HubspotSchemaType } from "api/schemas/types";
import Table from "components/Table";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";

import ActionsFormatter from "./formatters/ContactSchemasActionsFormatter";
import { useContactSchemasFilter } from "./useContactSchemasFilter";

const ContactSchemasTable: FC = () => {
  const intl = useIntl();
  const { filters, searchField } = useContactSchemasFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    hubspotContactsKeys.schemas(),
    getHubspotContactsSchemas,
    filters
  );

  const columns: Column<HubspotSchemaType>[] = [
    {
      Header: intl.formatMessage({ id: "GLOBAL.LABEL" }),
      Cell: ({ row }: CellProps<HubspotSchemaType>) => (
        <Box maxWidth="400px" sx={{ whiteSpace: "normal" }}>
          <Typography variant="body2" fontWeight="bold">
            {row.original.label}
          </Typography>
          <Typography variant="body2">{row.original.description}</Typography>
        </Box>
      ),
      maxWidth: 400,
    },
    {
      id: "fieldType",
      Header: intl.formatMessage({ id: "GLOBAL.TYPE" }),
      Cell: ({ row }: CellProps<HubspotSchemaType>) => (
        <>{row.original.fieldType}</>
      ),
    },
    {
      id: "groupName",
      Header: intl.formatMessage({ id: "GLOBAL.GROUP_NAME" }),
      Cell: ({ row }: CellProps<HubspotSchemaType>) => (
        <>{row.original.groupName}</>
      ),
    },
    {
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: CellProps<HubspotSchemaType>) => (
        <ActionsFormatter {...cell} />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={
              <FormattedMessage id="CONTACTS.SCHEMAS.TABLE.SEARCH_BY" />
            }
            {...searchField}
          />
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />
    </>
  );
};

export default ContactSchemasTable;
