import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { HubspotDomainType } from "./types";

export const getHubspotDomains = (
  queryParams?: QueryParamsType<HubspotDomainType>
): APICall<Paginated<HubspotDomainType[]>> =>
  request({
    url: `/hubspot-domains${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });
