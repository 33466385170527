import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import DealsSchemasHeader from "./DealsSchemasHeader";
import DealsSchemasTable from "./DealsSchemasTable";

const DealsSchemasPage = () => {
  return (
    <>
      <AccessTokenPicker scope={["crm.schemas.custom.read"]} />

      <Paper>
        <DealsSchemasHeader />
        <DealsSchemasTable />
      </Paper>
    </>
  );
};

export default DealsSchemasPage;
