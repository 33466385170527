import { Paper, Typography } from "@mui/material";
import { ApexOptions } from "apexcharts";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { QueryStatus } from "react-query";

import Chart from "components/Chart";
import theme from "theme";

const SiteHealthChart = () => {
  const { formatMessage } = useIntl();

  const chartOptions: ApexOptions = {
    chart: {
      stacked: true,
    },
    labels: [
      formatMessage({ id: "DASHBOARD.SITE_HEALTH.CORRECT" }),
      formatMessage({ id: "DASHBOARD.SITE_HEALTH.ERRORS" }),
    ],
    colors: [theme.palette.primary.main, "#eee"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  };

  const chartSeries: ApexOptions["series"] = [25, 35];

  return (
    <Paper sx={{ height: "150px", pt: 1 }}>
      <Typography textAlign="center">
        <FormattedMessage id="DASHBOARD.SITE_HEALTH.TITLE" />
      </Typography>
      <Chart
        type="donut"
        height="80%"
        options={chartOptions}
        series={chartSeries}
        status={QueryStatus.Success}
      />
    </Paper>
  );
};

export default SiteHealthChart;
