import { Mark } from "@mui/base";
import { Slider, Typography } from "@mui/material";
import { useField } from "formik";
import React from "react";

type Props = {
  name: string;
  label: JSX.Element;
  min: number;
  max: number;
  defaultValue?: number;
  marks?: Mark[];
};

const FormikRangeField = ({
  name,
  label,
  min,
  max,
  defaultValue,
  marks,
}: Props) => {
  const [field] = useField<number>(name);

  return (
    <>
      <Typography variant="body2">{label}</Typography>
      <Slider
        min={min}
        max={max}
        defaultValue={defaultValue}
        {...field}
        valueLabelDisplay="auto"
        marks={marks}
        sx={{
          maxWidth: "90%",
          marginLeft: "5%",
          "& .MuiSlider-markLabel:first-child": {
            marginLeft: "20px",
          },
        }}
      />
    </>
  );
};

export default FormikRangeField;
