import { useMemo } from "react";
import { queryCache, useMutation, useQuery } from "react-query";

import {
  getAccessTokens,
  postSelectAccessToken as postSelectAccessTokenAPI,
} from "api/hubspot-accessTokens";
import { hubspotAccessTokensKeys } from "api/hubspot-accessTokens/queries";
import { AccessTokenType } from "api/hubspot-accessTokens/types";
import { userKeys } from "api/user/queries";
import { QueryParamsType } from "api/utils";
import { useUserStore } from "components/stores/UserStore";

interface useAccessTokenPickerProps {
  scope: string[] | undefined;
}

export const useAccessTokenPicker = ({ scope }: useAccessTokenPickerProps) => {
  const [user] = useUserStore((s) => [s.user]);

  const accessTokensFilter: QueryParamsType<AccessTokenType> = useMemo(() => {
    const filter: QueryParamsType<AccessTokenType> = {
      limit: 1000,
    };

    if (scope?.length) {
      filter.filter = [
        {
          field: "scopes",
          operator: "eq",
          value: scope.join(","),
        },
      ];
    }

    return filter;
  }, [scope]);

  const { data: accessTokens, isLoading: areAccessTokensLoading } = useQuery(
    hubspotAccessTokensKeys.listFiltered(accessTokensFilter),
    async () => {
      const { data: res } = await getAccessTokens(accessTokensFilter);
      return res.data;
    }
  );

  const accessTokensOptions = useMemo(() => {
    if (accessTokens?.length) {
      return accessTokens.map((accessToken) => ({
        value: accessToken._id,
        label: accessToken.name,
      }));
    }

    return [];
  }, [accessTokens]);

  const [selectAccessToken] = useMutation(async (accessTokenId: string) => {
    const res = await postSelectAccessTokenAPI(accessTokenId);
    queryCache.removeQueries("hubspot");
    await queryCache.invalidateQueries(userKeys.me());
    return res.data;
  });

  const handleSelectAccessToken = async (accessTokenId: string) => {
    await selectAccessToken(accessTokenId);
  };

  return {
    selectedAccessToken: user?.selectedAccessToken?._id,
    accessTokensOptions,
    areAccessTokensLoading,
    handleSelectAccessToken,
  };
};
