import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { AccessTokenType, PutAccessTokenType } from "./types";

export const getAccessTokens = (
  queryParams?: QueryParamsType<AccessTokenType>
): APICall<Paginated<AccessTokenType[]>> =>
  request({
    url: `/hubspot-access-tokens${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const getSingleAccessToken = (
  accessTokenId: string
): APICall<{ data: AccessTokenType }> =>
  request({
    url: `/hubspot-access-tokens/${accessTokenId}`,
    method: "GET",
  });

export const putAccessToken = (
  accessTokenId: string,
  data: PutAccessTokenType
): APICall<Paginated<AccessTokenType[]>> =>
  request({
    data,
    url: `/hubspot-access-tokens/${accessTokenId}`,
    method: "PUT",
  });

export const deleteAccessToken = (
  accessTokenId: string
): APICall<Paginated<AccessTokenType[]>> =>
  request({
    url: `/hubspot-access-tokens/${accessTokenId}`,
    method: "DELETE",
  });

export const postSelectAccessToken = (accessToken: string): APICall<null> =>
  request({
    data: { accessToken },
    url: `/hubspot-access-tokens/`,
    method: "POST",
  });

export const getHubspotOauthURL = (scopes: string): APICall<{ data: string }> =>
  request({
    url: `/hubspot-access-tokens/url`,
    method: "GET",
    params: {
      scopes,
    },
  });

export const getHubspotOauthCallback = (
  code: string
): APICall<{ data: AccessTokenType }> =>
  request({
    url: `/hubspot-access-tokens/callback`,
    method: "GET",
    params: {
      code,
    },
  });
