import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { HubspotHubdbType } from "./types";

export const getHubspotHubdbs = (
  queryParams?: QueryParamsType<HubspotHubdbType>
): APICall<Paginated<HubspotHubdbType[]>> =>
  request({
    url: `/hubspot-hubdb${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });
