import { Edit as EditIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { queryCache, useMutation } from "react-query";

import { putAccessToken } from "api/hubspot-accessTokens";
import { hubspotAccessTokensKeys } from "api/hubspot-accessTokens/queries";
import {
  AccessTokenType,
  PutAccessTokenType,
} from "api/hubspot-accessTokens/types";

import AccessTokenForm, { AccessTokenFormValues } from "./AccessTokenForm";

interface EditAccessTokenModalProps {
  isOpen: boolean;
  handleClose: () => void;
  accessToken: AccessTokenType | undefined;
}

const EditAccessTokenModal = ({
  isOpen,
  handleClose,
  accessToken,
}: EditAccessTokenModalProps) => {
  const initialValues: AccessTokenFormValues = {
    name: accessToken?.name || null,
  };

  const [editAccessToken, { status }] = useMutation(
    async (data: PutAccessTokenType) => {
      const res = await putAccessToken(data._id, data);
      await queryCache.invalidateQueries(hubspotAccessTokensKeys.list());
      return res.data;
    }
  );

  const handleSubmit = async (values: AccessTokenFormValues) => {
    // TODO: change when connecting edit api call
    const mappedValues: PutAccessTokenType = {
      _id: accessToken!._id,
      ...values,
    };
    await editAccessToken(mappedValues, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <EditIcon sx={{ mr: 1 }} />
          <FormattedMessage id="ACCESS_TOKEN.EDIT.TITLE" />
        </Stack>
      </DialogTitle>
      <Divider />

      <AccessTokenForm
        edit
        submitStatus={status}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

export default EditAccessTokenModal;
