import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  // CellProps,
  Column,
} from "react-table";

import { getHubspotRedirects } from "api/hubspot-redirects";
import { hubspotRedirectsKeys } from "api/hubspot-redirects/queries";
// import { hasPermissions } from "components/stores/UserStore";
import { HubspotRedirectType } from "api/hubspot-redirects/types";
import Table from "components/Table";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
// import { useModal } from "utils/hooks/useModal";

import { useRedirectsFilter } from "./useRedirectsFilter";

const RedirectsTable: FC = () => {
  const intl = useIntl();
  const { filters, searchField } = useRedirectsFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    hubspotRedirectsKeys.list(),
    getHubspotRedirects,
    filters
  );

  const columns: Column<HubspotRedirectType>[] = [
    {
      accessor: "routePrefix",
      Header: intl.formatMessage({ id: "REDIRECTS.TABLE.FROM" }),
    },
    {
      accessor: "destination",
      Header: intl.formatMessage({ id: "REDIRECTS.TABLE.TO" }),
    },
    // {
    //   Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
    //   Cell: (cell: CellProps<AccessTokenType>) => (
    //     <OrganisationsActionsFormatter
    //       {...cell}
    //       handleOpenEdit={handleOpenEdit}
    //       handleOpenDelete={handleOpenDelete}
    //     />
    //   ),
    //   align: "right",
    // },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="REDIRECTS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />
    </>
  );
};

export default RedirectsTable;
