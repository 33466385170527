import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import RedirectsHeader from "./RedirectsHeader/RedirectsHeader";
import RedirectsTable from "./RedirectsTable";

const RedirectsPage = () => {
  return (
    <>
      <AccessTokenPicker />

      <Paper>
        <RedirectsHeader />
        <RedirectsTable />
      </Paper>
    </>
  );
};

export default RedirectsPage;
