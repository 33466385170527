import { AutoAwesomeMotion as AutoAwesomeMotionIcon } from "@mui/icons-material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import TableHeader from "components/Table/components/TableHeader";

const SitePagesHeader: FC = () => {
  return (
    <>
      <TableHeader
        icon={AutoAwesomeMotionIcon}
        label={<FormattedMessage id="PAGES.SITE_PAGES.TABLE.TITLE" />}
      ></TableHeader>
    </>
  );
};

export default SitePagesHeader;
