import { Grid } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import FormikPhoneField from "components/forms/FormikPhoneField";
import FormikTextField from "components/forms/FormikTextField";

const OrganisationForm: FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormikTextField
          name="name"
          label={<FormattedMessage id="GLOBAL.NAME" />}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="email"
          type="email"
          label={<FormattedMessage id="GLOBAL.EMAIL" />}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikPhoneField
          name="phone"
          type="phone"
          label={<FormattedMessage id="GLOBAL.PHONE" />}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="city"
          label={<FormattedMessage id="GLOBAL.CITY" />}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="address"
          label={<FormattedMessage id="GLOBAL.ADDRESS" />}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="postcode"
          label={<FormattedMessage id="GLOBAL.POSTCODE" />}
        />
      </Grid>
    </Grid>
  );
};

export default OrganisationForm;
