import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  CellProps,
  //  CellProps,
  Column,
} from "react-table";

import { getHubspotDeals } from "api/hubspot-deals";
import { hubspotDealsKeys } from "api/hubspot-deals/queries";
import { HubspotDealType } from "api/hubspot-deals/types";
// import { hasPermissions } from "components/stores/UserStore";
import Table from "components/Table";
import { hubspotFormatter } from "components/Table/formatters/hubspotFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { useModal } from "utils/hooks/useModal";

import DeleteDealModal from "./components/DeleteDealModal";
import DealsActionsFormatter from "./formatters/DealsActionsFormatter";
import { useDealsFilter } from "./useDealsFilter";

const DealsTable: FC = () => {
  const intl = useIntl();
  const { filters, searchField } = useDealsFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    hubspotDealsKeys.list(),
    getHubspotDeals,
    filters
  );

  const {
    isOpen: isOpenDelete,
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    context: deleteContext,
  } = useModal<HubspotDealType>();

  const columns: Column<HubspotDealType>[] = [
    {
      accessor: "id",
      Header: intl.formatMessage({ id: "GLOBAL.ID" }),
      Cell: ({ value }: CellProps<HubspotDealType>) => hubspotFormatter(value),
    },
    {
      id: "dealname",
      Header: intl.formatMessage({ id: "DEALS.TABLE.DEAL_NAME" }),
      Cell: ({ row }: CellProps<HubspotDealType>) => (
        <>{row.original.properties.dealname}</>
      ),
    },
    {
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: CellProps<HubspotDealType>) => (
        <DealsActionsFormatter {...cell} handleOpenDelete={handleOpenDelete} />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="DEALS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />

      <DeleteDealModal
        isOpen={isOpenDelete}
        handleClose={handleCloseDelete}
        deal={deleteContext}
      />
    </>
  );
};

export default DealsTable;
