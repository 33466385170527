import { Key as KeyIcon } from "@mui/icons-material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import TableHeader from "components/Table/components/TableHeader";

const ApiKeyHeader: FC = () => {
  return (
    <>
      <TableHeader
        icon={KeyIcon}
        label={<FormattedMessage id="API_KEY.TABLE.TITLE" />}
      ></TableHeader>
    </>
  );
};

export default ApiKeyHeader;
