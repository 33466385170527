import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import HubdbsHeader from "./HubdbsHeader/HubdbsHeader";
import HubdbsTable from "./HubdbsTable";

const HubdbsPage = () => {
  return (
    <>
      <AccessTokenPicker scope={["hubdb"]} />

      <Paper>
        <HubdbsHeader />
        <HubdbsTable />
      </Paper>
    </>
  );
};

export default HubdbsPage;
