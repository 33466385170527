import { Box, Button, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { useUserStore } from "components/stores/UserStore";

const ApiKeyGenerator = () => {
  const [user] = useUserStore((s) => [s.user]);

  const [keyShowed, setKeyShowed] = useState(false);

  const {
    enqueueSnackbar,
    // closeSnackbar
  } = useSnackbar();

  const handleCopy = () => {
    navigator.clipboard.writeText("TESTTESTTESTTESTTESTTESTTEST");
    enqueueSnackbar(<FormattedMessage id="API_KEY.COPIED_TEXT" />, {
      variant: "info",
    });
  };

  return (
    <Box margin={3}>
      {Boolean(user?.organisation?.apiKey) ? (
        <Stack direction="row">
          <Typography minWidth="220px">
            <FormattedMessage id="API_KEY.ACTIVE_API_KEY" />
          </Typography>
          <Box>
            {keyShowed ? (
              <Typography
                sx={{ paddingLeft: "5px", height: "30px" }}
                lineHeight={1}
              >
                TESTTESTTESTTESTTESTTESTTEST
              </Typography>
            ) : (
              <Typography
                variant="h2"
                sx={{ paddingLeft: "5px" }}
                lineHeight={1}
              >
                ******************************
              </Typography>
            )}
            <Stack direction="row" alignItems="center">
              <Button
                disabled={keyShowed}
                onClick={() => setKeyShowed(true)}
                sx={{ minWidth: "auto" }}
              >
                <FormattedMessage id="GLOBAL.SHOW" />
              </Button>
              <Box color="#ccc" mx={1.5}>
                |
              </Box>
              <Button
                onClick={handleCopy}
                disabled={!keyShowed}
                sx={{ minWidth: "auto" }}
              >
                <FormattedMessage id="GLOBAL.COPY" />
              </Button>
            </Stack>
          </Box>
        </Stack>
      ) : (
        <Box>
          <Typography mb={2}>
            <FormattedMessage id="API_KEY.NO_API_KEY" />
          </Typography>
          <Button variant="contained">
            <FormattedMessage id="GLOBAL.GENERATE" />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ApiKeyGenerator;
