import { MoveDown as MoveDownIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation, useQuery } from "react-query";
import * as yup from "yup";

import { getAccessTokens } from "api/hubspot-accessTokens";
import { hubspotAccessTokensKeys } from "api/hubspot-accessTokens/queries";
import { AccessTokenType } from "api/hubspot-accessTokens/types";
import { postTransferHubspotPagesLandingPage } from "api/hubspot-pages";
import { HubspotPageType, HubspotTransferType } from "api/hubspot-pages/types";
import { getPortalHubspotTemplates } from "api/hubspot-templates";
import { hubspotTemplatesKeys } from "api/hubspot-templates/queries";
import { HubspotTemplateType } from "api/hubspot-templates/types";
import { QueryParamsType } from "api/utils";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
import { AutocompleteOptiontType } from "components/forms/FormikSelect";
import FormikTextField from "components/forms/FormikTextField";
import { useUserStore } from "components/stores/UserStore";

export type TransferLandingPageFormValues = {
  portalId: string | null;
  pageId: string;
  name: string;
  templatePath: string;
};

interface TransferLandingPageModalProps {
  isOpen: boolean;
  handleClose: () => void;
  context?: HubspotPageType;
}

const TransferLandingPageModal = ({
  isOpen,
  handleClose,
  context,
}: TransferLandingPageModalProps) => {
  const [user] = useUserStore((s) => [s.user]);

  const schema = yup.object({
    portalId: yup.string().nullable().required(),
    name: yup.string().required(),
  });

  const initialValues: TransferLandingPageFormValues = {
    portalId: null,
    pageId: context?.id ?? "",
    name: context?.name ?? "",
    templatePath: context?.templatePath ?? "",
  };

  const [transferLandingPage, { isLoading }] = useMutation(
    async (values: HubspotTransferType) => {
      const res = await postTransferHubspotPagesLandingPage(values);
      return res.data;
    }
  );

  const handleSubmit = async (values: TransferLandingPageFormValues) => {
    const mappedValues: HubspotTransferType = {
      ...values,
      portalId: values.portalId ?? "",
    };
    await transferLandingPage(mappedValues, {
      onSuccess: () => handleClose(),
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });
  const { values } = formik;

  const accessTokensFilter: QueryParamsType<AccessTokenType> = {
    filter: [
      {
        field: "_id",
        operator: "ne",
        value: user?.selectedAccessToken?._id || null,
      },
      {
        field: "scopes",
        operator: "eq",
        value: "content",
      },
    ],
    limit: 1000,
  };

  const { data: accessTokens, isFetching: areTokensFetching } = useQuery(
    hubspotAccessTokensKeys.listFiltered(accessTokensFilter),
    async () => {
      const { data: res } = await getAccessTokens(accessTokensFilter);
      return res.data;
    }
  );

  const accessTokensOptions = useMemo<AutocompleteOptiontType[]>(() => {
    if (accessTokens) {
      return accessTokens?.map((token) => ({
        label: `${token.name}`,
        value: token.portalId,
      }));
    }
    return [];
  }, [accessTokens]);

  const templatesFilters: QueryParamsType<HubspotTemplateType> = {
    limit: 1000,
  };

  const { data: availableTemplates, isLoading: areAvailableTemplatesLoading } =
    useQuery(
      hubspotTemplatesKeys.listFilteredPortal(
        values.portalId,
        templatesFilters
      ),
      async () => {
        const { data: res } = await getPortalHubspotTemplates(
          values.portalId ?? "",
          templatesFilters
        );
        return res.data;
      },
      {
        enabled: Boolean(values.portalId),
      }
    );

  const pathExists = useMemo(() => {
    return availableTemplates?.some((grouTemplate) =>
      grouTemplate.children.some(
        (template) => template.path === values.templatePath
      )
    );
  }, [availableTemplates, values.templatePath]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <MoveDownIcon sx={{ mr: 1 }} />
          <FormattedMessage id="PAGES.LANDING_PAGES.TABLE.TRANSFER" />
        </Stack>
      </DialogTitle>
      <Divider />

      <FormikProvider value={formik}>
        <FormikForm>
          <DialogContent>
            <FormikAutocomplete
              name="portalId"
              label={
                <FormattedMessage id="PAGES.LANDING_PAGES.TABLE.TRANSFER.CHOOSE_TOKEN" />
              }
              options={accessTokensOptions}
              loading={areTokensFetching}
            />
            <FormikTextField
              label={
                <FormattedMessage id="PAGES.LANDING_PAGES.TABLE.TRANSFER.NAME" />
              }
              name="name"
            />
            <FormikTextField
              label={
                <FormattedMessage id="PAGES.LANDING_PAGES.TABLE.TRANSFER.PATH" />
              }
              name="templatePath"
            />

            {values.portalId && !areAvailableTemplatesLoading && !pathExists ? (
              <Alert severity="error" sx={{ mt: 1 }}>
                <FormattedMessage
                  id="PAGES.LANDING_PAGES.TABLE.TRANSFER.TEMPLATE_PATH_NOT_FOUND"
                  values={{ templatePath: values.templatePath }}
                />
              </Alert>
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button
              // data-testid="new-edit-port-close-button"
              onClick={handleClose}
            >
              <FormattedMessage id="GLOBAL.CLOSE" />
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading || areAvailableTemplatesLoading}
              disabled={Boolean(
                values.portalId || !areAvailableTemplatesLoading || !pathExists
              )}
            >
              <FormattedMessage id="GLOBAL.TRANSFER" />
            </LoadingButton>
          </DialogActions>
        </FormikForm>
      </FormikProvider>
    </Dialog>
  );
};

export default TransferLandingPageModal;
