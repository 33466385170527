import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { HubspotTransferWorkflowType, HubspotWorkflowType } from "./types";

export const getHubspotWorkflows = (
  queryParams?: QueryParamsType<HubspotWorkflowType>
): APICall<Paginated<HubspotWorkflowType[]>> =>
  request({
    url: `/hubspot-workflows${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const postTransferHubspotWorkflow = (
  data: HubspotTransferWorkflowType
): APICall<Paginated<HubspotWorkflowType[]>> =>
  request({
    data,
    url: `/hubspot-workflows/transfer`,
    method: "POST",
  });
