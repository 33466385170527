import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import ContactSchemasHeader from "./ContactSchemasHeader";
import ContactSchemasTable from "./ContactSchemasTable";

const ContactSchemasPage = () => {
  return (
    <>
      <AccessTokenPicker scope={["crm.schemas.custom.read"]} />

      <Paper>
        <ContactSchemasHeader />
        <ContactSchemasTable />
      </Paper>
    </>
  );
};

export default ContactSchemasPage;
