import { MoveDown as MoveDownIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "react-query";
import * as yup from "yup";

import { getHubspotOauthURL } from "api/hubspot-accessTokens";
import FormikCheckbox from "components/forms/FormikCheckbox";

interface NewAccessTokenModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const NewAccessTokenModal = ({
  isOpen,
  handleClose,
}: NewAccessTokenModalProps) => {
  const scopes = useMemo(() => {
    return [
      {
        group: "CMS",
        description: "Manage and view your CMS data",
        children: [
          "cms.knowledge_base.articles.read",
          "cms.knowledge_base.articles.write",
          "cms.knowledge_base.articles.publish",
          "cms.knowledge_base.settings.read",
          "cms.knowledge_base.settings.write",
          "cms.performance.read",
        ],
      },
      {
        group: "CRM",
        description: "Manage and view your CRM data",
        children: [
          "crm.lists.read",
          "crm.lists.write",
          "crm.objects.companies.read",
          "crm.objects.companies.write",
          "crm.objects.contacts.read",
          "crm.objects.contacts.write",
          "crm.objects.custom.read",
          "crm.objects.custom.write",
          "crm.objects.deals.read",
          "crm.objects.deals.write",
          "crm.objects.feedback_submissions.read",
          "crm.objects.line_items.read",
          "crm.objects.line_items.write",
          "crm.objects.marketing_events.read",
          "crm.objects.marketing_events.write",
          "crm.objects.owners.read",
          "crm.objects.quotes.read",
          "crm.schemas.companies.read",
          "crm.objects.quotes.write",
          "crm.schemas.companies.write",
          "crm.schemas.contacts.read",
          "crm.schemas.contacts.write",
          "crm.schemas.custom.read",
          "crm.schemas.deals.read",
          "crm.schemas.deals.write",
          "crm.schemas.line_items.read",
          "crm.schemas.quotes.read",
        ],
      },
      {
        group: "Settings",
        description: "Manage and view your account settings",
        children: [
          "settings.billing.write",
          "settings.users.read",
          "settings.users.write",
          "settings.users.teams.read",
          "settings.users.teams.write",
        ],
      },
      {
        group: "Standard",
        children: [
          "account-info.security.read",
          "accounting",
          "actions",
          "analytics.behavioral_events.send",
          "automation",
          "business-intelligence",
          "collector.graphql_query.execute",
          "collector.graphql_schema.read",
          "communication_preferences.read",
          "communication_preferences.read_write",
          "communication_preferences.write",
          "content",
          "conversations.read",
          "conversations.visitor_identification.tokens.create",
          "conversations.write",
          "crm.export",
          "crm.import",
          "e-commerce",
          "files",
          "forms",
          "files.ui_hidden.read",
          "forms-uploaded-files",
          "integration-sync",
          "hubdb",
          "media_bridge.read",
          "media_bridge.write",
          "oauth",
          "reports",
          "social",
          "sales-email-read",
          "tickets",
          "timeline",
          "transactional-email",
        ],
      },
    ];
  }, []);

  const schema = yup.object({});

  const [generateOauthUrl, { isLoading }] = useMutation(
    async (values: string[]) => {
      const { data: res } = await getHubspotOauthURL(values.join(" "));
      return res.data;
    }
  );

  const handleSubmit = async (values: any) => {
    const mappedValues = Object.keys(values)
      .filter((key) => Boolean(values[key]))
      .map((value) => value.replaceAll(":", "."));
    await generateOauthUrl(mappedValues, {
      //@ts-expect-error
      onSuccess: (link: string) => (window.location.href = link),
    });
  };

  const initialValues = useMemo(() => {
    let initalValuesMapped: { [key: string]: boolean } = {};

    scopes?.forEach((scopeGroup) => {
      scopeGroup.children.forEach((scope) => {
        initalValuesMapped[scope.replaceAll(".", ":")] = false;
      });
    });

    return initalValuesMapped;
  }, [scopes]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <MoveDownIcon sx={{ mr: 1 }} />
          <FormattedMessage id="ACCESS_TOKENS.NEW_TOKEN" />
        </Stack>
      </DialogTitle>
      <Divider />

      <FormikProvider value={formik}>
        <FormikForm>
          <DialogContent>
            <Stack direction="column" divider={<Divider sx={{ my: 2 }} />}>
              {scopes.map((scopeGroup) => (
                <Grid container spacing={2} width="100%">
                  <Grid item xs={5}>
                    <Typography fontWeight="bold" sx={{ mb: 0.5 }}>
                      {scopeGroup.group}
                    </Typography>
                    <Typography>{scopeGroup.description}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    {scopeGroup.children.map((scope) => (
                      <FormikCheckbox
                        key={scope}
                        label={scope}
                        name={scope.replaceAll(".", ":")}
                      />
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              // data-testid="new-edit-port-close-button"
              onClick={handleClose}
            >
              <FormattedMessage id="GLOBAL.CLOSE" />
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              <FormattedMessage id="ACCESS_TOKENS.NEW_TOKEN.AUTHORIZE" />
            </LoadingButton>
          </DialogActions>
        </FormikForm>
      </FormikProvider>
    </Dialog>
  );
};

export default NewAccessTokenModal;
