import { HubspotSchemaType } from "api/schemas/types";
import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { HubspotCompanyType } from "./types";

export const getHubspotCompanies = (
  queryParams?: QueryParamsType<HubspotCompanyType>
): APICall<Paginated<HubspotCompanyType[]>> =>
  request({
    url: `/hubspot-companies${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const getHubspotCompaniesSchemas = (
  queryParams?: QueryParamsType<HubspotSchemaType>
): APICall<Paginated<HubspotSchemaType[]>> =>
  request({
    url: `/hubspot-companies/schemas${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });
