import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import SitePagesHeader from "./SitePagesHeader/SitePagesHeader";
import SitePagesTable from "./SitePagesTable";

const SitePagesPage = () => {
  return (
    <>
      <AccessTokenPicker scope={["content"]} />

      <Paper>
        <SitePagesHeader />
        <SitePagesTable />
      </Paper>
    </>
  );
};

export default SitePagesPage;
