import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBoxOutlined as CheckBoxOutlinedIcon,
} from "@mui/icons-material";
import { Box, CircularProgress } from "@mui/material";
// import { BottlingType } from "api/bottling/types";
import { FormattedMessage } from "react-intl";
import { queryCache, useMutation } from "react-query";
import { CellProps } from "react-table";

import { postSelectAccessToken as postSelectAccessTokenAPI } from "api/hubspot-accessTokens";
import { AccessTokenType } from "api/hubspot-accessTokens/types";
import { userKeys } from "api/user/queries";
import IconButtonWithTooltip from "components/Icons/IconButtonWithTooltip";
// import { hasPermissions } from "components/stores/UserStore";

interface Props<T extends {}> extends CellProps<T> {
  selectedAccessToken: string;
}

const SelectFormatter = ({
  row,
  selectedAccessToken,
}: Props<AccessTokenType>) => {
  const [selectAccessToken, { isLoading }] = useMutation(async () => {
    const res = await postSelectAccessTokenAPI(row.original._id);
    queryCache.removeQueries("hubspot");
    await queryCache.invalidateQueries(userKeys.me());
    return res.data;
  });

  const handleSelectAccessToken = async () => {
    if (row.original._id !== selectedAccessToken) {
      await selectAccessToken();
    }
  };

  return (
    <Box>
      {/* {hasPermissions("update:bottling") && ( */}
      {row.original._id === selectedAccessToken ? (
        <IconButtonWithTooltip
          tooltipText={
            <FormattedMessage id="ACCESS_TOKENS.TABLE.ACTIONS.SELECT" />
          }
          // data-testid="edit-port-button"
          color="primary"
        >
          <CheckBoxOutlinedIcon />
        </IconButtonWithTooltip>
      ) : isLoading ? (
        <CircularProgress
          // variant="determinate"

          sx={{
            width: "20px!important",
            height: "20px!important",
          }}
        />
      ) : (
        <IconButtonWithTooltip
          tooltipText={
            <FormattedMessage id="ACCESS_TOKENS.TABLE.ACTIONS.SELECT" />
          }
          // data-testid="edit-port-button"
          color="primary"
          onClick={handleSelectAccessToken}
        >
          <CheckBoxOutlineBlankIcon />
        </IconButtonWithTooltip>
      )}
      {/* )} */}
    </Box>
  );
};

export default SelectFormatter;
