import LoginLayout from "containers/login/components/LoginLayout";

import ForgotPasswordForm from "./components/ForgotPasswordForm";

const LoginPage = () => {
  return (
    <LoginLayout>
      <ForgotPasswordForm />
    </LoginLayout>
  );
};

export default LoginPage;
