export const bytesFormatter = (bytes: number) => {
  const decimals = 2;
  if (!+bytes) return "0 B";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
export const bytesColorFormatter = (bytes: number) => {
  if (bytes < 1_000_000) {
    return "#008000";
  } else if (bytes < 10_000_000) {
    return "#bbbb00";
  } else return "#b41515";
};
