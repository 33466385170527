import { Divider, Paper } from "@mui/material";
import { FC } from "react";

import ProfileForm from "./ProfileForm/ProfileForm";
import ProfileHeader from "./ProfileHeader/ProfileHeader";

const UserProfile: FC = () => {
  return (
    <>
      <Paper>
        <ProfileHeader />
        <Divider />
        <ProfileForm />
      </Paper>
    </>
  );
};

export default UserProfile;
