import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  Grid,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { getHubspotPagesLandingPages } from "api/hubspot-pages";
import { hubspotPagesKeys } from "api/hubspot-pages/queries";
import { HubspotPageType } from "api/hubspot-pages/types";
import NoImagePNG from "assets/images/no-image-placeholder.png";
// import { hasPermissions } from "components/stores/UserStore";
import Table from "components/Table";
import { hubspotFormatter } from "components/Table/formatters/hubspotFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { useModal } from "utils/hooks/useModal";

import SearchAndReplaceLandingPageModal from "./components/SearchAndReplaceLandingPageModal";
import TransferLandingPageModal from "./components/TransferLandingPageModal";
import LandingPagesActionsFormatter from "./formatters/LandingPagesActionsFormatter";
import { useLandingPagesFilter } from "./useLandingPagesFilter";

const LandingPagesTable: FC = () => {
  const intl = useIntl();
  const { filters, searchField } = useLandingPagesFilter();

  const {
    isOpen: isOpenTransfer,
    handleClose: handleCloseTransfer,
    handleOpen: handleOpenTransfer,
    context: transferContext,
  } = useModal<HubspotPageType>();
  const {
    isOpen: isOpenSearchAndReplace,
    handleClose: handleCloseSearchAndReplace,
    handleOpen: handleOpenSearchAndReplace,
    context: searchAndReplaceContext,
  } = useModal<HubspotPageType>();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    hubspotPagesKeys.landingPagesList(),
    getHubspotPagesLandingPages,
    filters
  );

  const columns: Column<HubspotPageType>[] = [
    {
      accessor: "id",
      Header: intl.formatMessage({ id: "GLOBAL.ID" }),
      Cell: ({ value }: CellProps<HubspotPageType>) => hubspotFormatter(value),
    },
    {
      accessor: "name",
      Header: intl.formatMessage({ id: "GLOBAL.NAME" }),
      Cell: ({ row, value }: CellProps<HubspotPageType>) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box sx={{ border: "2px solid #Eee" }}>
            {row.original.featuredImage ? (
              <img
                src={row.original.featuredImage}
                alt={row.original.featuredImageAlt}
                width="80"
                height="53"
                loading="lazy"
                style={{ objectFit: "cover" }}
              />
            ) : (
              <img
                src={NoImagePNG}
                alt="Empty Asset"
                width="80"
                height="53"
                loading="lazy"
                style={{ objectFit: "cover" }}
              />
            )}
          </Box>
          {row.original.published ? (
            <Link component="a" href={row.original.url} target="_blank">
              {value}
            </Link>
          ) : (
            <Box>{value}</Box>
          )}
        </Stack>
      ),
    },
    {
      accessor: "published",
      Header: intl.formatMessage({ id: "GLOBAL.PUBLISHED" }),
      Cell: ({ value }: CellProps<HubspotPageType>) => (
        <>
          {value ? (
            <Chip
              icon={<CheckCircleIcon />}
              label={intl.formatMessage({ id: "GLOBAL.PUBLISHED" })}
              size="small"
              color="success"
              sx={{ color: "#fff" }}
            />
          ) : (
            <Chip
              icon={<CancelIcon />}
              label={intl.formatMessage({ id: "GLOBAL.DRAFT" })}
              size="small"
              color="error"
            />
          )}
        </>
      ),
    },
    {
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: CellProps<HubspotPageType>) => (
        <LandingPagesActionsFormatter
          {...cell}
          handleOpenTransfer={handleOpenTransfer}
          handleOpenSearchAndReplace={handleOpenSearchAndReplace}
        />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={
              <FormattedMessage id="PAGES.LANDING_PAGES.TABLE.SEARCH_BY" />
            }
            {...searchField}
          />
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />

      <TransferLandingPageModal
        isOpen={isOpenTransfer}
        handleClose={handleCloseTransfer}
        context={transferContext}
      />

      <SearchAndReplaceLandingPageModal
        isOpen={isOpenSearchAndReplace}
        handleClose={handleCloseSearchAndReplace}
        context={searchAndReplaceContext}
      />
    </>
  );
};

export default LandingPagesTable;
