import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import ContactsHeader from "./ContactsHeader/ContactsHeader";
import ContactsTable from "./ContactsTable";

const ContactsPage = () => {
  return (
    <>
      <AccessTokenPicker />

      <Paper>
        <ContactsHeader />
        <ContactsTable />
      </Paper>
    </>
  );
};

export default ContactsPage;
