import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { HubspotRedirectType } from "./types";

export const getHubspotRedirects = (
  queryParams?: QueryParamsType<HubspotRedirectType>
): APICall<Paginated<HubspotRedirectType[]>> =>
  request({
    url: `/hubspot-redirects${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });
