import { MoveDown as MoveDownIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation, useQuery } from "react-query";
import * as yup from "yup";

import { getAccessTokens } from "api/hubspot-accessTokens";
import { hubspotAccessTokensKeys } from "api/hubspot-accessTokens/queries";
import { AccessTokenType } from "api/hubspot-accessTokens/types";
import { postTransferHubspotContact } from "api/hubspot-contacts";
import {
  HubspotContactType,
  HubspotTransferContactType,
} from "api/hubspot-contacts/types";
import { QueryParamsType } from "api/utils";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
import { AutocompleteOptiontType } from "components/forms/FormikSelect";
import { useUserStore } from "components/stores/UserStore";

export type TransferContactFormValues = {
  portalId: string | null;
};

interface TransferContactModalProps {
  isOpen: boolean;
  handleClose: () => void;
  context?: HubspotContactType;
}

const TransferContactModal = ({
  isOpen,
  handleClose,
  context,
}: TransferContactModalProps) => {
  const [user] = useUserStore((s) => [s.user]);

  const schema = yup.object({
    portalId: yup.string().nullable().required(),
    name: yup.string().required(),
  });

  const initialValues: TransferContactFormValues = {
    portalId: null,
  };

  const [transferContact, { isLoading }] = useMutation(
    async (values: HubspotTransferContactType) => {
      const res = await postTransferHubspotContact(values);
      return res.data;
    }
  );

  const handleSubmit = async (values: TransferContactFormValues) => {
    const mappedValues: HubspotTransferContactType = {
      ...values,
      portalId: values.portalId ?? "",
    };
    await transferContact(mappedValues, {
      onSuccess: () => handleClose(),
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });
  const { values } = formik;

  const accessTokensFilter: QueryParamsType<AccessTokenType> = {
    filter: [
      {
        field: "_id",
        operator: "ne",
        value: user?.selectedAccessToken?._id || null,
      },
      {
        field: "scopes",
        operator: "eq",
        value: "content",
      },
    ],
    limit: 1000,
  };

  const { data: accessTokens, isFetching: areTokensFetching } = useQuery(
    hubspotAccessTokensKeys.listFiltered(accessTokensFilter),
    async () => {
      const { data: res } = await getAccessTokens(accessTokensFilter);
      return res.data;
    }
  );

  const accessTokensOptions = useMemo<AutocompleteOptiontType[]>(() => {
    if (accessTokens) {
      return accessTokens?.map((token) => ({
        label: `${token.name}`,
        value: token.portalId,
      }));
    }
    return [];
  }, [accessTokens]);

  // const templatesFilters: QueryParamsType<HubspotTemplateType> = {
  //   limit: 1000,
  // };

  // const { data: availableTemplates, isLoading: areAvailableTemplatesLoading } =
  //   useQuery(
  //     hubspotTemplatesKeys.listFilteredPortal(
  //       values.portalId,
  //       templatesFilters
  //     ),
  //     async () => {
  //       const { data: res } = await getPortalHubspotTemplates(
  //         values.portalId ?? "",
  //         templatesFilters
  //       );
  //       return res.data;
  //     },
  //     {
  //       enabled: Boolean(values.portalId),
  //     }
  //   );

  // const pathExists = useMemo(() => {
  //   return availableTemplates?.some((grouTemplate) =>
  //     grouTemplate.children.some(
  //       (template) => template.path === values.templatePath
  //     )
  //   );
  // }, [availableTemplates, values.templatePath]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <MoveDownIcon sx={{ mr: 1 }} />
          <FormattedMessage id="CONTACTS.TABLE.TRANSFER" />
        </Stack>
      </DialogTitle>
      <Divider />

      <FormikProvider value={formik}>
        <FormikForm>
          <DialogContent>
            <FormikAutocomplete
              name="portalId"
              label={
                <FormattedMessage id="PAGES.LANDING_PAGES.TABLE.TRANSFER.CHOOSE_TOKEN" />
              }
              options={accessTokensOptions}
              loading={areTokensFetching}
            />

            {/* {values.portalId && !areAvailableTemplatesLoading && !pathExists ? (
              <Alert severity="error" sx={{ mt: 1 }}>
                <FormattedMessage
                  id="PAGES.LANDING_PAGES.TABLE.TRANSFER.TEMPLATE_PATH_NOT_FOUND"
                  values={{ templatePath: values.templatePath }}
                />
              </Alert>
            ) : null} */}
          </DialogContent>

          <DialogActions>
            <Button
              // data-testid="new-edit-port-close-button"
              onClick={handleClose}
            >
              <FormattedMessage id="GLOBAL.CLOSE" />
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              disabled={Boolean(values.portalId)}
            >
              <FormattedMessage id="GLOBAL.TRANSFER" />
            </LoadingButton>
          </DialogActions>
        </FormikForm>
      </FormikProvider>
    </Dialog>
  );
};

export default TransferContactModal;
