import {
  CheckCircle as CheckCircleIcon,
  ReportProblem as ReportProblemIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Box, Grid, InputAdornment, Stack, TextField } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { getHubspotDomains } from "api/hubspot-domains";
import { hubspotDomainsKeys } from "api/hubspot-domains/queries";
// import { hasPermissions } from "components/stores/UserStore";
import { HubspotDomainType } from "api/hubspot-domains/types";
import Table from "components/Table";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
// import { useModal } from "utils/hooks/useModal";

import { useDomainsFilter } from "./useDomainsFilter";

const DomainsTable: FC = () => {
  const intl = useIntl();
  const { filters, searchField } = useDomainsFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    hubspotDomainsKeys.list(),
    getHubspotDomains,
    filters
  );

  const columns: Column<HubspotDomainType>[] = [
    {
      accessor: "domain",
      Header: intl.formatMessage({ id: "DOMAINS.TABLE.DOMAIN" }),
    },
    {
      accessor: "isDnsCorrect",
      Header: intl.formatMessage({ id: "DOMAINS.TABLE.DNS" }),
      Cell: ({ value }: CellProps<HubspotDomainType>) => (
        <>
          {value ? (
            <Stack direction="row">
              <Box>
                <CheckCircleIcon color="success" sx={{ width: 20, mr: 0.5 }} />
                <FormattedMessage id="GLOBAL.VERIFIED" />
              </Box>
            </Stack>
          ) : (
            <Stack direction="row">
              <Box>
                <ReportProblemIcon
                  color="warning"
                  sx={{ width: 20, mr: 0.5 }}
                />
                <FormattedMessage id="GLOBAL.NOT_VERIFIED" />
              </Box>
            </Stack>
          )}
        </>
      ),
    },
    {
      accessor: "isSslEnabled",
      Header: intl.formatMessage({ id: "DOMAINS.TABLE.SSL" }),
      Cell: ({ value }: CellProps<HubspotDomainType>) => (
        <>
          {value ? (
            <Stack direction="row">
              <Box>
                <CheckCircleIcon color="success" sx={{ width: 20, mr: 0.5 }} />
                <FormattedMessage id="GLOBAL.ENABLED" />
              </Box>
            </Stack>
          ) : (
            <Stack direction="row">
              <Box>
                <ReportProblemIcon
                  color="warning"
                  sx={{ width: 20, mr: 0.5 }}
                />
                <FormattedMessage id="GLOBAL.DISABLED" />
              </Box>
            </Stack>
          )}
        </>
      ),
    },
    // {
    //   accessor: "name",
    //   Header: intl.formatMessage({ id: "GLOBAL.NAME" }),
    // },
    // {
    //   Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
    //   Cell: (cell: CellProps<AccessTokenType>) => (
    //     <OrganisationsActionsFormatter
    //       {...cell}
    //       handleOpenEdit={handleOpenEdit}
    //       handleOpenDelete={handleOpenDelete}
    //     />
    //   ),
    //   align: "right",
    // },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="DOMAINS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />
    </>
  );
};

export default DomainsTable;
