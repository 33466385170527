import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import CompaniesHeader from "./CompaniesHeader/CompaniesHeader";
import CompaniesTable from "./CompaniesTable";

const CompaniesPage = () => {
  return (
    <>
      <AccessTokenPicker />

      <Paper>
        <CompaniesHeader />
        <CompaniesTable />
      </Paper>
    </>
  );
};

export default CompaniesPage;
