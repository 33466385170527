import { KeyOff } from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  alpha,
  useTheme,
} from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

export interface SidebarMenuItemsProps {
  icon: JSX.Element;
  text: string | JSX.Element;
  link: string;
  onClick?: () => void;
  missingScopes?: string[];
}

const SidebarMenuItem = ({
  icon,
  text,
  link,
  onClick,
  missingScopes,
}: SidebarMenuItemsProps) => {
  const theme = useTheme();
  const history = useHistory();
  const { pathname } = useLocation();

  const isDisabled = Boolean(missingScopes?.length);
  const isSelected = pathname.includes(link);

  const tooltipTitle = missingScopes?.length
    ? `Missing scopes: ${missingScopes.join(", ")}`
    : "";

  return (
    <Tooltip title={tooltipTitle} placement="right">
      <MenuItem
        onClick={() => {
          if (!isDisabled) {
            history.push(link);
            onClick && onClick();
          }
        }}
        sx={{
          py: 1.5,
          pl: 0,
          mb: 0.5,
          mx: 1,
          borderRadius: 2,
          overflow: "hidden",
          "&.Mui-selected": {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
          },
          "&.Mui-selected:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.3),
          },
          backgroundColor: isDisabled ? theme.palette.grey[200] : "transparent",
          // pointerEvents: isDisabled ? "none" : "all",
        }}
        selected={isSelected}
      >
        <ListItemIcon
          sx={{
            width: 48,
            justifyContent: "center",
            color: isSelected
              ? theme.palette.primary.dark
              : theme.palette.grey[500],
            "& svg g [fill]": {
              fill: isSelected
                ? theme.palette.primary.dark
                : theme.palette.grey[500],
            },
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          sx={{
            color: isSelected
              ? theme.palette.primary.dark
              : theme.palette.grey[800],
          }}
        >
          {text}
        </ListItemText>

        {Boolean(missingScopes?.length) && (
          <KeyOff sx={{ fontSize: "16px", color: theme.palette.grey[400] }} />
        )}
      </MenuItem>
    </Tooltip>
  );
};

export default SidebarMenuItem;
