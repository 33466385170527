import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import destilleryLogo from "assets/logos/logo.svg";

const Footer = () => (
  <Stack
    component="footer"
    direction="row"
    alignItems="center"
    sx={{ minHeight: 46, px: 3 }}
    justifyContent="space-between"
    data-cy="footer"
  >
    <Stack direction="row" alignItems="center">
      <img
        alt="destillery"
        style={{
          height: 35,
          objectFit: "contain",
          objectPosition: "0 50%",
          paddingRight: 8,
        }}
        src={destilleryLogo}
      />
    </Stack>

    <Stack direction="row" alignItems="center">
      <Link
        component={RouterLink}
        to={`/support`}
        sx={{ textDecoration: "none", mr: 2 }}
      >
        <FormattedMessage id="FOOTER.SUPPORT" />
      </Link>
      <Link
        component={RouterLink}
        to={`/privacy-policy`}
        sx={{ textDecoration: "none", mr: 2 }}
      >
        <FormattedMessage id="FOOTER.PRIVACY_POLICY" />
      </Link>
      <Typography sx={{ color: (t) => t.palette.grey[500] }}>
        {new Date().getFullYear()} &copy;
      </Typography>
    </Stack>
  </Stack>
);

export default Footer;
