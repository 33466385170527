import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { HubspotTemplateType } from "./types";

export const getHubspotTemplates = (
  queryParams?: QueryParamsType<HubspotTemplateType>
): APICall<Paginated<HubspotTemplateType[]>> =>
  request({
    url: `/hubspot-templates${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const getPortalHubspotTemplates = (
  portalId: string,
  queryParams?: QueryParamsType<HubspotTemplateType>
): APICall<Paginated<HubspotTemplateType[]>> =>
  request({
    url: `/hubspot-templates${normalizeQueryParams(queryParams)}`,
    method: "GET",
    params: {
      portalId,
    },
  });
