import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "react-query";

import { HubspotDealType } from "api/hubspot-deals/types";

interface DeleteDealModalProps {
  isOpen: boolean;
  handleClose: () => void;
  deal: HubspotDealType | undefined;
}

const DeleteDealModal = ({
  isOpen,
  handleClose,
  deal,
}: DeleteDealModalProps) => {
  const [deletedeal, { status }] = useMutation(async (dealId: string) => {
    // const res = await deleteBottlingAPI(bottlingId);
    // await queryCache.invalidateQueries(bottlingKeys.list());
    // return res.data;
  });

  const handleDelete = async () => {
    await deletedeal(deal!.id, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <DeleteIcon sx={{ mr: 1 }} />
          <FormattedMessage id="DEALS.DELETE.TITLE" />
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <FormattedMessage
          id="DEALS.DELETE.WARNING"
          values={{ name: <b>{deal?.properties?.dealname}</b> }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage
            // data-testid="delete-port-close-button"
            id="GLOBAL.CANCEL"
          />
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleDelete}
          loading={status === "loading"}
        >
          <FormattedMessage id="GLOBAL.DELETE" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDealModal;
