import { AutoFixHigh as AutoFixHighIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import { FormattedMessage } from "react-intl";
import { useMutation } from "react-query";
import * as yup from "yup";

import { postSearchAndReplaceHubspotPagesLandingPage } from "api/hubspot-pages";
import {
  HubspotPageType,
  HubspotSearchAndReplaceType,
} from "api/hubspot-pages/types";
import FormikTextField from "components/forms/FormikTextField";

export type SearchAndReplaceLandingPageFormValues = {
  pageId: string;
  search: string;
  replaceWith: string;
};

interface SearchAndReplaceLandingPageModalProps {
  isOpen: boolean;
  handleClose: () => void;
  context?: HubspotPageType;
}

const SearchAndReplaceLandingPageModal = ({
  isOpen,
  handleClose,
  context,
}: SearchAndReplaceLandingPageModalProps) => {
  const schema = yup.object({
    search: yup.string().required(),
    replaceWith: yup.string().required(),
  });

  const initialValues: SearchAndReplaceLandingPageFormValues = {
    pageId: context?.id ?? "",
    search: "",
    replaceWith: "",
  };

  const [searchAndReplaceLandingPage, { isLoading }] = useMutation(
    async (values: HubspotSearchAndReplaceType) => {
      const res = await postSearchAndReplaceHubspotPagesLandingPage(values);
      return res.data;
    }
  );

  const handleSubmit = async (
    values: SearchAndReplaceLandingPageFormValues
  ) => {
    const mappedValues: HubspotSearchAndReplaceType = {
      ...values,
    };
    await searchAndReplaceLandingPage(mappedValues, {
      onSuccess: () => handleClose(),
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AutoFixHighIcon sx={{ mr: 1 }} />
          <FormattedMessage id="PAGES.LANDING_PAGES.TABLE.SEARCH_AND_REPLACE" />
        </Stack>
      </DialogTitle>
      <Divider />

      <FormikProvider value={formik}>
        <FormikForm>
          <DialogContent>
            <FormikTextField
              label={
                <FormattedMessage id="PAGES.LANDING_PAGES.TABLE.SEARCH_AND_REPLACE.SEARCH" />
              }
              name="search"
            />
            <FormikTextField
              label={
                <FormattedMessage id="PAGES.LANDING_PAGES.TABLE.SEARCH_AND_REPLACE.REPLACE_WITH" />
              }
              name="replaceWith"
            />
          </DialogContent>

          <DialogActions>
            <Button
              // data-testid="new-edit-port-close-button"
              onClick={handleClose}
            >
              <FormattedMessage id="GLOBAL.CLOSE" />
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              <FormattedMessage id="GLOBAL.SUBMIT" />
            </LoadingButton>
          </DialogActions>
        </FormikForm>
      </FormikProvider>
    </Dialog>
  );
};

export default SearchAndReplaceLandingPageModal;
