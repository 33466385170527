import {
  Compress as CompressIcon,
  //   DeleteOutlined as DeleteIcon,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { CellProps } from "react-table";

import { HubspotFileType } from "api/hubspot-files/types";
import IconButtonWithTooltip from "components/Icons/IconButtonWithTooltip";

interface Props<T extends {}> extends CellProps<T> {
  handleOpenCompression: (ctx?: T) => void;
}

const compressableExtensions = ["png", "jpg", "jpeg"];

const ActionsFormatter = ({
  row,
  handleOpenCompression,
}: Props<HubspotFileType>) => {
  const canCompress =
    compressableExtensions.includes(row.original.extension) &&
    Boolean(row.original.meta.allows_anonymous_access);

  return (
    <Box>
      <IconButtonWithTooltip
        tooltipText={<FormattedMessage id="GLOBAL.COMPRESS" />}
        // data-testid="delete-port-button"
        color="primary"
        onClick={() => handleOpenCompression(row.original)}
        disabled={!canCompress}
      >
        <CompressIcon />
      </IconButtonWithTooltip>
    </Box>
  );
};

export default ActionsFormatter;
