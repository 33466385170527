import { HubspotSchemaType } from "api/schemas/types";
import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { HubspotContactType, HubspotTransferContactType } from "./types";

export const getHubspotContacts = (
  queryParams?: QueryParamsType<HubspotContactType>
): APICall<Paginated<HubspotContactType[]>> =>
  request({
    url: `/hubspot-contacts${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const getHubspotContactsSchemas = (
  queryParams?: QueryParamsType<HubspotSchemaType>
): APICall<Paginated<HubspotSchemaType[]>> =>
  request({
    url: `/hubspot-contacts/schemas${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const postTransferHubspotContact = (
  data: HubspotTransferContactType
): APICall<Paginated<HubspotContactType[]>> =>
  request({
    data,
    url: `/hubspot-contact/transfer`,
    method: "POST",
  });
