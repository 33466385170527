import { Paper, alpha } from "@mui/material";
import { Formik, Form as FormikForm } from "formik";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as LogoSVG } from "assets/logos/logo-icon.svg";
import FormikAutocomplete from "components/forms/FormikAutocomplete";

import { useAccessTokenPicker } from "./useAccessTokenPicker";

interface AccessTokenPickerProps {
  scope?: string[];
}

const AccessTokenPicker: FC<AccessTokenPickerProps> = ({ scope }) => {
  const {
    selectedAccessToken,
    accessTokensOptions,
    areAccessTokensLoading,
    handleSelectAccessToken,
  } = useAccessTokenPicker({ scope });

  return (
    <Paper
      sx={{
        backgroundColor: (t) => alpha(t.palette.primary.main, 0.3),
        py: 3,
        mb: 2,
        px: 2,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <LogoSVG
        style={{
          position: "absolute",
          left: "-100px",
          height: "500px",
          width: "500px",
          top: "-200px",
          opacity: 0.5,
        }}
      />
      <Formik
        enableReinitialize
        initialValues={{
          accessToken: selectedAccessToken,
        }}
        onSubmit={() => {}}
      >
        <FormikForm>
          <FormikAutocomplete
            name="accessToken"
            label={<FormattedMessage id="GLOBAL.ACCESS_TOKEN" />}
            options={accessTokensOptions}
            onChange={(e, value) => {
              handleSelectAccessToken(value?.value ?? "");
            }}
            loading={areAccessTokensLoading}
            disableClearable
          />
        </FormikForm>
      </Formik>
    </Paper>
  );
};

export default AccessTokenPicker;
