import {
  AutoAwesomeMotion as AutoAwesomeMotionIcon,
  BusinessCenter as BusinessCenterIcon,
  Business as BusinessIcon,
  ContactPage as ContactPageIcon,
  Dashboard as DashboardIcon,
  DataObject as DataObjectIcon,
  FileCopy as FileCopyIcon,
  Group as GroupIcon,
  Key as KeyIcon,
  Language as LanguageIcon,
  LocalOffer as LocalOfferIcon,
  Margin as MarginIcon,
  Storage as StorageIcon,
  SwapHoriz as SwapHorizIcon,
  Webhook as WebhookIcon,
} from "@mui/icons-material";
import { Box, Divider, MenuList, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { UserOrgTypeRoleType } from "api/user/types";
import {
  hasPermissions,
  hasRole,
  hasScopes,
} from "components/stores/UserStore";
import { PermissionsType } from "components/stores/UserStore/permissions";

import SidebarMenuDropdown from "./SidebarMenuDropdown";
import SidebarMenuItem, { SidebarMenuItemsProps } from "./SidebarMenuItem";

export type SidebarMenuListChild = Array<
  SidebarMenuItemsProps & {
    scopes?: string[];
    requiredPermissions?: PermissionsType[];
    requiredRole?: UserOrgTypeRoleType[];
    dropdown?: Array<
      Omit<
        SidebarMenuItemsProps & {
          requiredRole?: UserOrgTypeRoleType[];
          scopes?: string[];
        },
        "icon"
      >
    >;
  }
>;

type SidebarMenuList = Array<{
  id: string;
  text: string | JSX.Element;
  list: SidebarMenuListChild;
}>;

const sidebarMenuListOperational: SidebarMenuListChild = [
  {
    icon: <DashboardIcon />,
    text: <FormattedMessage id="HEADER.DASHBOARD" />,
    link: "/dashboard",
  },
];

const sidebarMenuListCMS: SidebarMenuListChild = [
  {
    icon: <LanguageIcon />,
    text: <FormattedMessage id="HEADER.DOMAINS" />,
    link: "/cms/domains",
    scopes: ["content"],
  },
  {
    icon: <MarginIcon />,
    text: <FormattedMessage id="HEADER.TEMPLATES" />,
    link: "/cms/templates",
    scopes: ["content"],
  },
  {
    icon: <FileCopyIcon />,
    text: <FormattedMessage id="HEADER.FILES" />,
    link: "/cms/files",
    scopes: ["files"],
  },
  {
    icon: <AutoAwesomeMotionIcon />,
    text: <FormattedMessage id="HEADER.PAGES" />,
    link: "/cms/pages",
    scopes: ["content"],
    dropdown: [
      {
        text: <FormattedMessage id="HEADER.PAGES.SITE_PAGES" />,
        link: "/cms/pages/site-pages",
        scopes: ["content"],
      },
      {
        text: <FormattedMessage id="HEADER.PAGES.LANDING_PAGES" />,
        link: "/cms/pages/landing-pages",
        scopes: ["content"],
      },
      {
        text: <FormattedMessage id="HEADER.PAGES.BLOGS" />,
        link: "/cms/pages/blogs",
        scopes: ["content"],
      },
    ],
  },
  {
    icon: <StorageIcon />,
    text: <FormattedMessage id="HEADER.HUBDB" />,
    link: "/cms/hubdb",
    scopes: ["hubdb"],
  },
  {
    icon: <SwapHorizIcon />,
    text: <FormattedMessage id="HEADER.REDIRECTS" />,
    link: "/cms/redirects",
    scopes: ["content"],
  },
];

const sidebarMenuListCRM: SidebarMenuListChild = [
  {
    icon: <ContactPageIcon />,
    text: <FormattedMessage id="HEADER.CONTACTS" />,
    link: "/crm/contacts",
    dropdown: [
      {
        text: <FormattedMessage id="GLOBAL.SCHEMAS" />,
        link: "/crm/contact-schemas",
        scopes: ["crm.objects.custom.read"],
      },
      {
        text: <FormattedMessage id="GLOBAL.OBJECTS" />,
        link: "/crm/contacts",
        scopes: ["crm.objects.contacts.read"],
      },
    ],
  },
  {
    icon: <BusinessIcon />,
    text: <FormattedMessage id="HEADER.COMPANIES" />,
    link: "/crm/companies",
    dropdown: [
      {
        text: <FormattedMessage id="GLOBAL.SCHEMAS" />,
        link: "/crm/company-schemas",
        scopes: ["crm.objects.custom.read"],
      },
      {
        text: <FormattedMessage id="GLOBAL.OBJECTS" />,
        link: "/crm/companies",
        scopes: ["crm.objects.companies.read"],
      },
    ],
  },
  {
    icon: <LocalOfferIcon />,
    text: <FormattedMessage id="HEADER.DEALS" />,
    link: "/crm/deals",
    dropdown: [
      {
        text: <FormattedMessage id="GLOBAL.SCHEMAS" />,
        link: "/crm/deal-schemas",
        scopes: ["crm.objects.custom.read"],
      },
      {
        text: <FormattedMessage id="GLOBAL.OBJECTS" />,
        link: "/crm/deals",
        scopes: ["crm.objects.deals.read"],
      },
    ],
  },
  {
    icon: <DataObjectIcon />,
    text: <FormattedMessage id="HEADER.CUSTOM_OBJECTS" />,
    link: "/crm/custom-objects",
    scopes: ["crm.objects.custom.read"],
    dropdown: [],
  },
  {
    icon: <WebhookIcon />,
    text: <FormattedMessage id="HEADER.WORKFLOWS" />,
    link: "/crm/workflows",
    scopes: ["automation"],
  },
];

const sidebarMenuListAdmin: SidebarMenuListChild = [
  {
    icon: <KeyIcon />,
    text: <FormattedMessage id="HEADER.ACCESS_TOKENS" />,
    // requiredPermissions: ["read:organisations"],
    link: "/access-tokens",
  },
  {
    icon: <GroupIcon />,
    text: <FormattedMessage id="HEADER.USERS" />,
    requiredPermissions: ["read:users"],
    requiredRole: ["portal-admin", "organisation-owner"],
    link: "/users",
  },
  {
    icon: <BusinessCenterIcon />,
    text: <FormattedMessage id="HEADER.ORGANISATIONS" />,
    requiredPermissions: ["read:organisations"],
    requiredRole: ["portal-admin"],
    link: "/organisations",
  },
  {
    icon: <BusinessCenterIcon />,
    text: <FormattedMessage id="HEADER.MY_ORGANISATION" />,
    requiredPermissions: ["read:organisations"],
    requiredRole: ["organisation-owner", "organisation-employee"],
    link: "/my-organisation",
  },
];

const sidebarMenuList: SidebarMenuList = [
  {
    id: "operational",
    text: <FormattedMessage id="HEADER.OPERATIONAL" />,
    list: sidebarMenuListOperational,
  },
  {
    id: "cms",
    text: <FormattedMessage id="HEADER.CMS" />,
    list: sidebarMenuListCMS,
  },
  {
    id: "crm",
    text: <FormattedMessage id="HEADER.CRM" />,
    list: sidebarMenuListCRM,
  },

  {
    id: "admin",
    text: <FormattedMessage id="HEADER.ADMIN" />,
    list: sidebarMenuListAdmin,
  },
];

interface Props {
  onClose?: () => void;
}

const SidebarMenu = ({ onClose }: Props) => {
  return (
    <MenuList
      sx={{
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "calc(100% - 45px)",
        pt: 0,
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {sidebarMenuList.map(({ id, list, text }) => {
        const filteredList = list.filter(
          ({ requiredRole, requiredPermissions }) =>
            hasPermissions(requiredPermissions) && hasRole(requiredRole)
        );

        if (!filteredList.length) {
          return null;
        }

        return (
          <Box key={id}>
            <Typography
              variant="body2"
              sx={{
                ml: 1,
                my: 1.5,
                width: "80%",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                fontWeight: (t) => t.typography.fontWeightBold,
              }}
            >
              {text}
            </Typography>
            {filteredList.map(({ icon, text, link, dropdown, scopes }) => {
              const missingScope = !hasScopes(scopes);

              if (dropdown) {
                return (
                  <SidebarMenuDropdown
                    key={link}
                    icon={icon}
                    text={text}
                    link={link}
                    list={dropdown}
                    onClick={onClose}
                    missingScopes={missingScope ? scopes : []}
                  />
                );
              }

              return (
                <SidebarMenuItem
                  key={link}
                  icon={icon}
                  text={text}
                  link={link}
                  onClick={onClose}
                  missingScopes={missingScope ? scopes : []}
                />
              );
            })}
            <Divider sx={{ mx: 1 }} />
          </Box>
        );
      })}
    </MenuList>
  );
};

export default SidebarMenu;
