import { Paper } from "@mui/material";
import React from "react";

import { AccessTokenType } from "api/hubspot-accessTokens/types";
import { useModal } from "utils/hooks/useModal";

import AccessTokenCallback from "./AccessTokenCallback/AccessTokenCallback";
import AccessTokensHeader from "./AccessTokensHeader/AccessTokensHeader";
import AccessTokensTable from "./AccessTokensTable";
import EditAccessTokenModal from "./AccessTokensTable/components/EditAccessTokenModal";

const AccessTokens = () => {
  const {
    isOpen: isEditOpen,
    handleClose: handleCloseEdit,
    handleOpen: handleOpenEdit,
    context: editContext,
  } = useModal<AccessTokenType>();

  return (
    <>
      <AccessTokenCallback handleOpenEdit={handleOpenEdit} />
      <Paper>
        <AccessTokensHeader />
        <AccessTokensTable handleOpenEdit={handleOpenEdit} />
      </Paper>

      <EditAccessTokenModal
        isOpen={isEditOpen}
        handleClose={handleCloseEdit}
        accessToken={editContext}
      />
    </>
  );
};

export default AccessTokens;
