import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import DealsHeader from "./DealsHeader/DealsHeader";
import DealsTable from "./DealsTable";

const DealsPage = () => {
  return (
    <>
      <AccessTokenPicker />

      <Paper>
        <DealsHeader />
        <DealsTable />
      </Paper>
    </>
  );
};

export default DealsPage;
