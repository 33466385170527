import { QueryKey } from "react-query";

const hubspotTemplatesKeys = {
  all: ["hubspot", "templates"] as const,
  list: () => [...hubspotTemplatesKeys.all, "list"],
  listFiltered: (filters: QueryKey) => [
    ...hubspotTemplatesKeys.list(),
    filters,
  ],
  listPortal: (portalId: QueryKey) => [
    ...hubspotTemplatesKeys.all,
    "list",
    portalId,
  ],
  listFilteredPortal: (portalId: QueryKey, filters: QueryKey) => [
    ...hubspotTemplatesKeys.listPortal(portalId),
    filters,
  ],
};

export { hubspotTemplatesKeys };
