import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import WorkflowsHeader from "./WorkflowsHeader/WorkflowsHeader";
import WorkflowsTable from "./WorkflowsTable";

const WorkflowsPage = () => {
  return (
    <>
      <AccessTokenPicker />

      <Paper>
        <WorkflowsHeader />
        <WorkflowsTable />
      </Paper>
    </>
  );
};

export default WorkflowsPage;
