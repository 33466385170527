import { Paper } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import LandingPagesHeader from "./LandingPagesHeader/LandingPagesHeader";
import LandingPagesTable from "./LandingPagesTable";

const LandingPagesPage = () => {
  return (
    <>
      <AccessTokenPicker scope={["content"]} />

      <Paper>
        <LandingPagesHeader />
        <LandingPagesTable />
      </Paper>
    </>
  );
};

export default LandingPagesPage;
