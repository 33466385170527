import React, { FC, useEffect, useMemo } from "react";
import { queryCache, useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import { getHubspotOauthCallback } from "api/hubspot-accessTokens";
import { hubspotAccessTokensKeys } from "api/hubspot-accessTokens/queries";
import { AccessTokenType } from "api/hubspot-accessTokens/types";

interface AccessTokenCallbackProps {
  handleOpenEdit: (ctx?: AccessTokenType | undefined) => void;
}

const AccessTokenCallback: FC<AccessTokenCallbackProps> = ({
  handleOpenEdit,
}) => {
  const history = useHistory();
  const { search } = useLocation();

  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const code = query.get("code");

  const [submitCode] = useMutation(async (code: string) => {
    const res = await getHubspotOauthCallback(code);
    await queryCache.invalidateQueries(hubspotAccessTokensKeys.list());
    return res.data;
  });

  useEffect(() => {
    if (code) {
      (async function () {
        await submitCode(code, {
          onSettled: () => history.push("/access-tokens"),
          onSuccess: (data) => handleOpenEdit(data.data),
        });
      })();
    }
  }, [code, query, submitCode, history, handleOpenEdit]);

  return <></>;
};

export default AccessTokenCallback;
