import { Grid } from "@mui/material";

import AccessTokenPicker from "components/AccessToken/AccessTokenPicker";

import SiteHealthChart from "./components/SiteHealthChart";

const DashboardPage = () => {
  return (
    <>
      <AccessTokenPicker />

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <SiteHealthChart />
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardPage;
