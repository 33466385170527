import { Key as KeyIcon, Link as LinkIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import TableHeader from "components/Table/components/TableHeader";
import { useModal } from "utils/hooks/useModal";

import NewAccessTokenModal from "../components/NewAccessTokenModal";

const AccessTokensHeader: FC = () => {
  const {
    isOpen: isOpenNew,
    handleClose: handleCloseNew,
    handleOpen: handleOpenNew,
  } = useModal();

  return (
    <>
      <TableHeader
        icon={KeyIcon}
        label={<FormattedMessage id="ACCESS_TOKENS.TABLE.TITLE" />}
      >
        {/* {hasPermissions("write:users") && ( */}
        <Button
          // data-testid="new-port-button"
          variant="contained"
          onClick={handleOpenNew}
        >
          <LinkIcon sx={{ mr: 0.5 }} />
          <FormattedMessage id="ACCESS_TOKENS.AUTHORIZE_PORTAL" />
        </Button>
        {/* )} */}
      </TableHeader>

      <NewAccessTokenModal isOpen={isOpenNew} handleClose={handleCloseNew} />
    </>
  );
};

export default AccessTokensHeader;
