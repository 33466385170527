import {
  DeleteOutlined as DeleteIcon,
  EditOutlined as EditIcon,
} from "@mui/icons-material";
import { Box } from "@mui/material";
// import { BottlingType } from "api/bottling/types";
import { FormattedMessage } from "react-intl";
import { CellProps } from "react-table";

import { AccessTokenType } from "api/hubspot-accessTokens/types";
import IconButtonWithTooltip from "components/Icons/IconButtonWithTooltip";
// import { hasPermissions } from "components/stores/UserStore";

interface Props<T extends {}> extends CellProps<T> {
  handleOpenEdit: (ctx?: T) => void;
  handleOpenDelete: (ctx?: T) => void;
}

const ActionsFormatter = ({
  row,
  handleOpenEdit,
  handleOpenDelete,
}: Props<AccessTokenType>) => {
  return (
    <Box>
      {/* {hasPermissions("update:bottling") && ( */}
      <IconButtonWithTooltip
        tooltipText={<FormattedMessage id="GLOBAL.EDIT" />}
        // data-testid="edit-port-button"
        color="primary"
        onClick={() => handleOpenEdit(row.original)}
      >
        <EditIcon />
      </IconButtonWithTooltip>
      {/* )} */}
      {/* {hasPermissions("delete:bottling") && ( */}
      <IconButtonWithTooltip
        tooltipText={<FormattedMessage id="GLOBAL.DELETE" />}
        // data-testid="delete-port-button"
        color="primary"
        onClick={() => handleOpenDelete(row.original)}
      >
        <DeleteIcon />
      </IconButtonWithTooltip>
      {/* )} */}
    </Box>
  );
};

export default ActionsFormatter;
