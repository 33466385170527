import { queryCache, useMutation, useQuery } from "react-query";

import {
  editOrganisation as editOrganisationAPI,
  getMyOrganisation,
} from "api/organisations";
import { organisationsKeys } from "api/organisations/queries";
import { EditOrganisationType } from "api/organisations/types";
import { useUserStore } from "components/stores/UserStore";

type OrganisationFormValues = {
  name: string;
  email: string;
  city: string;
  address: string;
  phone: string;
  postcode: string;
};

export const useMyOrganisation = () => {
  const [user] = useUserStore((s) => [s.user]);

  const { data: organisation } = useQuery(
    organisationsKeys.mine(),
    async () => {
      const { data: res } = await getMyOrganisation();
      return res.data;
    }
  );

  const initialValues: OrganisationFormValues = {
    name: organisation?.name || "",
    email: organisation?.email || "",
    city: organisation?.city || "",
    address: organisation?.address || "",
    phone: organisation?.phone || "",
    postcode: organisation?.postcode || "",
  };

  const [editOrganisation, { isLoading: isSubmitting }] = useMutation(
    async (data: EditOrganisationType) => {
      const res = await editOrganisationAPI(data);
      await queryCache.invalidateQueries(organisationsKeys.mine());
      return res.data;
    }
  );

  const handleSubmit = async (values: OrganisationFormValues) => {
    const mappedValues: EditOrganisationType = {
      _id: user?.organisation._id || "",
      ...values,
    };
    await editOrganisation(mappedValues);
  };

  return {
    organisation,
    initialValues,
    handleSubmit,
    isSubmitting,
  };
};
