import { HubspotSchemaType } from "api/schemas/types";
import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { HubspotDealType } from "./types";

export const getHubspotDeals = (
  queryParams?: QueryParamsType<HubspotDealType>
): APICall<Paginated<HubspotDealType[]>> =>
  request({
    url: `/hubspot-deals${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const getHubspotDealsSchemas = (
  queryParams?: QueryParamsType<HubspotSchemaType>
): APICall<Paginated<HubspotSchemaType[]>> =>
  request({
    url: `/hubspot-deals/schemas${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });
