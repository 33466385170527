import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { getHubspotCompanies } from "api/hubspot-companies";
import { hubspotCompaniesKeys } from "api/hubspot-companies/queries";
import { HubspotCompanyType } from "api/hubspot-companies/types";
import Table from "components/Table";
import { hubspotFormatter } from "components/Table/formatters/hubspotFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { useModal } from "utils/hooks/useModal";

import DeleteCompanytModal from "./components/DeleteCompanyModal";
import CompaniesActionsFormatter from "./formatters/CompaniesActionsFormatter";
import { useCompaniesFilter } from "./useCompaniesFilter";

const CompaniesTable: FC = () => {
  const intl = useIntl();
  const { filters, searchField } = useCompaniesFilter();

  const {
    isOpen: isOpenDelete,
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    context: deleteContext,
  } = useModal<HubspotCompanyType>();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    hubspotCompaniesKeys.list(),
    getHubspotCompanies,
    filters
  );

  const columns: Column<HubspotCompanyType>[] = [
    {
      accessor: "id",
      Header: intl.formatMessage({ id: "GLOBAL.ID" }),
      Cell: ({ value }: CellProps<HubspotCompanyType>) =>
        hubspotFormatter(value),
    },
    {
      id: "name",
      Header: intl.formatMessage({ id: "COMPANIES.TABLE.NAME" }),
      Cell: ({ row }: CellProps<HubspotCompanyType>) => (
        <>{row.original.properties.name}</>
      ),
    },
    {
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: CellProps<HubspotCompanyType>) => (
        <CompaniesActionsFormatter
          {...cell}
          handleOpenDelete={handleOpenDelete}
        />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="COMPANIES.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />

      <DeleteCompanytModal
        isOpen={isOpenDelete}
        handleClose={handleCloseDelete}
        company={deleteContext}
      />
    </>
  );
};

export default CompaniesTable;
